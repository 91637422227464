import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import alert from '../../../js/alert';
import api from '../../../js/api';
import HookFormItems from '../../form/HookFormItems';
import PsButton from '../../element/PsButton';
import { RiSave3Fill } from 'react-icons/ri';


const PhoneNumberMinPriceForm = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, control, handleSubmit, formState: { errors, isDirty, isValid }, } = useForm({ reValidateMode: 'onChange', });
    const [errorInfo, setErrorInfo] = useState({});

    useImperativeHandle(ref, () => ({
        handleSubmit: handleSubmit(onSubmit, onError),
        errors: errors,
        isDirty: isDirty,
        isValid: isValid,
        isSubmitting: isSubmitting,
        errorInfo: errorInfo,
    }));

    const inputFields = [
        {
            label: t('min_price'),
            requiredOptions: {
                required: t('please_enter_field_value'),
                pattern: {
                    value: /^[0-9.]+$/,
                    message: t('numeric_input_warning')
                }
            },
            attr: {
                name: "amount",
                type: "number",
                min: 0,
                step: 0.01,
                inputMode: "tel",
                placeholder: t('amount'),
                id: "form-min-price-amount-field",
                autoComplete: "off",
                value: props.minPrice
            },
            callback: {
                onChange: (e) => {
                    props.setMinPrice(e.target.value);
                }
            }
        },
    ];

    const onSubmit = async (data) => {
        try {
            if (props.onSubmit) {
                props.onSubmit(data);
            }
            setErrorInfo({});
            setIsSubmitting(true);
            const submit = await api.patch(`phone-number/config/min-price`, data);
            if (props.onSuccess) {
                await props.onSuccess(submit);
            }
            alert.toastSuccess(alert.tryGetSuccessMessage(submit));
        } catch (error) {
            onError(error);
        } finally {
            setIsSubmitting(false);
        }
    }

    const onError = async (err) => {
        const errMessage = alert.tryGetErrorMessage(err);
        if (props.onError) {
            props.onError({ message: errMessage });
        }
        alert.toastError(errMessage);
        setErrorInfo({ message: errMessage });
        throw new Error(errMessage);
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit, onError)} id="form-min-price" autoComplete="off" ref={formRef}>
                <fieldset disabled={isSubmitting}>
                    <div className="row my-0">
                        <HookFormItems formFields={inputFields} register={register} control={control} errors={errors} isDirty={isDirty} isValid={isValid} />
                        <div className="col-12">
                            <PsButton type="submit" icon={<RiSave3Fill />} isLoading={isSubmitting}>{t('save')}</PsButton>
                        </div>
                    </div>
                </fieldset>
            </form>
        </>
    )
})

export default PhoneNumberMinPriceForm