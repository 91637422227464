import React from 'react';
import { useTranslation } from 'react-i18next';
import MainNav from '../nav/MainNav';

export default function PagePhoneNumberAddExcel() {
    const { t } = useTranslation();
    return (
        <>
            <MainNav pageTitle={t('add_phone_number_excel')}>
            </MainNav>
        </>
    )
}
