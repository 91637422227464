import React, { useEffect, useState, useRef } from 'react';
import MainNav from './../nav/MainNav';
import { useTranslation } from 'react-i18next';
import api from './../../js/api';
import ContactEditSocial from './contact/ContactEditSocial';
import ContactEdit from './contact/ContactEdit';

export default function PageContact() {
  const { t } = useTranslation();
  const [contactData, setContactData] = useState(null);
  const formWhatsappRef = useRef();
  const formFbRef = useRef();
  const formLineRef = useRef();
  const formGeneralRef = useRef();

  useEffect(() => {
    fetchContact();
  }, []);

  const fetchContact = async () => {
    try {
      const fetch = await api.get(`web-contact`);
      setContactData(fetch.data);
    } catch (error) {

    } finally {

    }
  }

  return (
    <>
      <MainNav pageTitle={t('contact_info')}></MainNav>
      {contactData !== null &&
        <>
          <ContactEdit contactData={contactData} ref={formGeneralRef} />
          <ContactEditSocial social="whatsapp" socialTranslate="whatsapp" contactData={contactData} ref={formWhatsappRef} />
          <ContactEditSocial social="fb" socialTranslate="fb" contactData={contactData} ref={formFbRef} />
          <ContactEditSocial social="line" socialTranslate="line_app" contactData={contactData} ref={formLineRef} />
        </>
      }
    </>
  )
}
