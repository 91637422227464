import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import alert from '../../js/alert';
import MainNav from '../nav/MainNav';
import img_logo from './../../logo.png';
import { NavLink } from 'react-router-dom';
import ForgetPasswordForm from './forget-password/ForgetPasswordForm';

export default function PageForgetPassword(props) {
    const { t } = useTranslation();
    const formRef = useRef();
    const [errorInfo, setErrorInfo] = useState({});

    const onSuccess = (result) => {
        alert.toastSuccess(alert.tryGetSuccessMessage(result));
    }

    const onSubmit = (data) => {
        setErrorInfo({});
    }

    const onError = (error) => {
        setErrorInfo(error);
    }

    const ErrorMessage = () => {
        return (<>{
            errorInfo.message ?
                <div className="row mt-3">
                    <div className="col-12">
                        <div className="alert alert-danger" role="alert">
                            {errorInfo.message}
                        </div>
                    </div>
                </div>
                : null
        }</>)
    }

    return (props.standalone ?
        <div id="app">
            <div className="container d-flex align-items-center flex-column justify-content-center">
                <div className="w-100">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6">
                            <div className="px-4 py-5 rounded shadow-sm bg-light">
                                <img src={img_logo} className="page-form-logo" alt={process.env.REACT_APP_NAME} />

                                <div className="row">
                                    <h4 className="text-center ">{t('forget_password')}</h4>
                                    <p className="text-black-50">{t('forget_password_instruction')}</p>
                                </div>

                                <div>
                                    <ForgetPasswordForm onSuccess={onSuccess} onSubmit={onSubmit} onError={onError} ref={formRef} />
                                    <ErrorMessage />
                                </div>

                                <div className="d-flex justify-content-between mt-3">
                                    <NavLink to={{ pathname: '/login' }}>{t('login')}</NavLink>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        :
        <>
            <MainNav pageTitle={t('forget_password')}></MainNav>
            <div className="row">
                <div className="col-lg-6">
                    <ForgetPasswordForm onSuccess={onSuccess} onSubmit={onSubmit} onError={onError} ref={formRef} />
                    <ErrorMessage />
                </div>
            </div>
        </>
    )
}
