import React from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './App.css';
// import AppProtectedRoute from './component/AppProtectedRoute';
import PageNotFound from './component/page/PageNotFound';
import PageHome from './component/page/PageHome';
import PageLogin from './component/page/PageLogin';
import PageProfile from './component/page/PageProfile';
import PageProfileChangePassword from './component/page/PageProfileChangePassword';
import PageSalesPerson from './component/page/PageSalesPerson';
import PageSalesPersonAdd from './component/page/PageSalesPersonAdd';
import PageSalesPersonInfo from './component/page/PageSalesPersonInfo';
import PagePhoneNumber from './component/page/PagePhoneNumber';
import PagePhoneNumberAdd from './component/page/PagePhoneNumberAdd';
import PagePhoneNumberAddExcel from './component/page/PagePhoneNumberAddExcel';
import PageFaq from './component/page/PageFaq';
import PageFaqAdd from './component/page/PageFaqAdd';
import PageFaqInfo from './component/page/PageFaqInfo';
import PageForgetPassword from './component/page/PageForgetPassword';
import PageResetPassword from './component/page/PageResetPassword';
import PageWrapper from './component/page/PageWrapper';
import PagePhoneNumberConfig from './component/page/PagePhoneNumberConfig';
import PageStorePhoneNumberAdd from './component/page/PageStorePhoneNumberAdd';
import PageStorePhoneNumberAddExcel from './component/page/PageStorePhoneNumberAddExcel';
import PagePhoneNumberSearchLogs from './component/page/PagePhoneNumberSearchLogs';
import PageContact from './component/page/PageContact';
import DataStoreContextProvider from './context/DataStoreContext';
import { useAuthContext } from './context/AuthContext';

function App() {
  const authContext = useAuthContext();

  return (
    <>
      {authContext.isCompleted && <>
        {authContext.activeUser !== null ?
          <BrowserRouter>
            <DataStoreContextProvider>
              <PageWrapper>
                <Routes>
                  <Route path="/home" element={<PageHome />} />
                  <Route path="/profile/change-password" element={<PageProfileChangePassword />} />
                  <Route path="/profile" element={<PageProfile />} />
                  <Route path="/sales-person/:uid/store/phone-number/add-excel" element={<PageStorePhoneNumberAddExcel />} />
                  <Route path="/sales-person/:uid/store/phone-number/add" element={<PageStorePhoneNumberAdd />} />
                  <Route path="/sales-person/:uid" element={<PageSalesPersonInfo />} />
                  <Route path="/sales-person/add" element={<PageSalesPersonAdd />} />
                  <Route path="/sales-person" element={<PageSalesPerson />} />
                  <Route path="/phone-number/search/logs" element={<PagePhoneNumberSearchLogs />} />
                  <Route path="/phone-number/add-excel" element={<PagePhoneNumberAddExcel />} />
                  <Route path="/phone-number/add" element={<PagePhoneNumberAdd />} />
                  <Route path="/phone-number/config" element={<PagePhoneNumberConfig />} />
                  <Route path="/phone-number" element={<PagePhoneNumber />} />
                  <Route path="/faq/:id" element={<PageFaqInfo />} />
                  <Route path="/faq/add" element={<PageFaqAdd />} />
                  <Route path="/faq" element={<PageFaq />} />
                  <Route path="/web-contact" element={<PageContact />} />
                  <Route path="/forget-password" element={<PageForgetPassword standalone={false} />} />
                  <Route path="/reset-password/:code" element={<PageResetPassword standalone={false} />} />
                  <Route path="/login" element={<Navigate to="/" />} />
                  <Route path="/" element={<PageHome />} />
                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </PageWrapper>
            </DataStoreContextProvider>
          </BrowserRouter>
          :
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<PageLogin />} />
              <Route path="/forget-password" element={<PageForgetPassword standalone={true} />} />
              <Route path="/reset-password/:code" element={<PageResetPassword standalone={true} />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          </BrowserRouter>}
      </>}
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover
      />
    </>
  );
}

export default App;
