import React from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../js/api';
import alert from '../../../js/alert';
import { useLoadingContext } from '../../../context/LoadingContext';

export default function SalesPersonInfo(props) {
    const { t } = useTranslation();
    const { presentLoading, dismissLoading } = useLoadingContext();

    const updateActiveStatus = async () => {
        try {
            presentLoading();
            const data = {active_status: props.info.active_status === 1 ? 0 : 1};
            const submit = await api.put(`sales-person/status/${props.info.id}`, data);
            props.onSubmitFormSuccess(submit, data);
        } catch (error) {
            alert.toastError(alert.tryGetErrorMessage(error));            
        } finally {
            dismissLoading();
        }
    }

    return (
        <div>
            <table className="table w-100">
                <tbody>
                    <tr>
                        <th style={{ width: "30%" }}>{t('fullname')}</th>
                        <td>{props.info.fullname}</td>
                    </tr>
                    <tr>
                        <th>{t('store_name')}</th>
                        <td>{props.info.store_name}</td>
                    </tr>
                    <tr>
                        <th>{t('store_detail')}</th>
                        <td>{props.info.store_detail}</td>
                    </tr>
                    <tr>
                        <th>{t('email')}</th>
                        <td>{props.info.email}</td>
                    </tr>
                    <tr>
                        <th>{t('phone_number')}</th>
                        <td>{props.info.phone}</td>
                    </tr>
                    <tr>
                        <th>{t('whatsapp')}</th>
                        <td>{props.info.whatsapp}</td>
                    </tr>
                    <tr>
                        <th>{t('line')}</th>
                        <td>{props.info.line}</td>
                    </tr>
                    <tr>
                        <th>{t('fb')}</th>
                        <td>{props.info.fb}</td>
                    </tr>
                    <tr>
                        <th>{t('status')}</th>
                        <td>
                            <strong className={`text-${props.info.active_status === 1 ? 'success' : 'danger'}`}>{props.info.active_status === 1 ? t('active') : t('suspense')}</strong>
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" role="switch" id="sp-status-check" checked={props.info.active_status === 1} onChange={() => {updateActiveStatus();}} />
                                <label className="form-check-label" htmlFor="sp-status-check">{t('toggle_to_update_status')}</label>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}
