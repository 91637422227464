import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FormAddPhoneNumber from './phone-number/FormAddPhoneNumber';
import PsButton from '../element/PsButton';
import { FaTrash } from 'react-icons/fa';
import { AiOutlinePlusCircle, AiOutlineSave } from 'react-icons/ai';
import api from '../../js/api';
import { useLoadingContext } from '../../context/LoadingContext';
import alert from '../../js/alert';
import { usePrefixContext } from '../../context/PrefixContext';
import { currencyFormat } from '../../js/utilities';
import { useParams, useNavigate } from 'react-router-dom';
import { MdStorefront } from 'react-icons/md';
import MainNav from '../nav/MainNav';

export default function PageStorePhoneNumberAdd() {
    const { t } = useTranslation();
    const { uid } = useParams();
    const navigate = useNavigate();
    const [formIds, setFormIds] = useState([]);
    const refs = useRef([]);
    const { presentLoading, dismissLoading } = useLoadingContext();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successInfo, setSuccessInfo] = useState({});
    const [errorInfo, setErrorInfo] = useState({});
    const { prefix, pnConfig, getPnConfig } = usePrefixContext();
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        getPnConfig();
        fetchUser();
    }, []);

    useEffect(() => {
        if (formIds.length === 0) {
            addNewForm();
        }
    }, [formIds]);

    const fetchUser = async () => {
        try {
            presentLoading();
            const fetch = await api.get(`sales-person/${uid}`);
            setUserInfo(fetch.data);
        } catch (error) {
            alert.toastError(alert.tryGetErrorMessage(error));
        } finally {
            dismissLoading();
        }
    }

    const addNewForm = () => {
        const timestamp = new Date().getTime();
        const tmp = formIds;
        tmp.push(timestamp);
        setFormIds([...tmp]);
    }

    const removeForm = (index) => {
        if (formIds.length <= 1) {
            return;
        }
        const tmp = formIds;
        tmp.splice(index, 1);
        setFormIds([...tmp]);
    }

    const submitAllForm = async () => {
        let hasError = false;
        const formData = [];
        for (const element of refs.current) {
            if (element === null) {
                continue;
            }
            element.setErrorInfo({});
            const errors = [];
            const uuid = element.uuid;
            const price = parseFloat(element.price);
            const packageType = element.packageType;
            const paid = element.paidType;
            const provider = element.provider;
            const numbers = element.numbers.trim();
            const saleStatus = element.saleStatus;

            if (numbers.length === 0) {
                errors.push(t('please_enter_field_value'));
            }

            if (price < pnConfig. pn_min_price) {
                errors.push(t('invalid_price_min', { min_price: pnConfig. pn_min_price }));
            }

            if (provider === 0) {
                errors.push(t('please_select_provider'));
            }

            formData.push({
                key: uuid,
                numbers: numbers,
                package: packageType,
                paid: paid,
                provider: provider,
                price: price,
                sale_status: saleStatus,
            });

            if (errors.length > 0) {
                element.setErrorInfo({ message: errors.join("<br />") });
                hasError = true;
            }
        }

        if (hasError) {
            return;
        }
        const submit = await submitPhoneNumber(formData);
    }

    const submitPhoneNumber = async (formData) => {
        try {
            setErrorInfo({});
            setSuccessInfo({});
            setIsSubmitting(true);
            presentLoading();
            const submit = await api.post(`sales-person/${uid}/phone-number`, formData);
            await onSubmitSuccess(submit.data);
            return submit;
        } catch (error) {
            onSubmitError(error.response);
            return error.response;
        } finally {
            dismissLoading();
            setIsSubmitting(false);
        }
    }

    const onSubmitSuccess = async (result) => {
        await clearForms();
        setSuccessInfo(result);
    }

    const onSubmitError = (error) => {
        if (error) {
            alert.toastError(alert.tryGetErrorMessage(error));
            // return;
        };

        const errors = error.data;
        Object.entries(errors.details).map(([key, value]) => {
            for (const element of refs.current) {
                if (element === null) {
                    continue;
                }
                const uuid = element.uuid;
                if (uuid === parseInt(key)) {
                    if (value.status !== true) {
                        element.setErrorInfo({ message: value.message });
                    }
                }
            }
        });

        setErrorInfo({ message: errors.message });
    }

    const clearForms = async () => {
        await setErrorInfo({});
        await setSuccessInfo({});
        await setFormIds([]);
    }

    return (
        <>
            <MainNav pageTitle={t('add_phone_number_manual')}>
                <div>
                    <PsButton handleClick={() => navigate(`/sales-person/${uid}`)} color="info" isDisabled={isSubmitting}><MdStorefront /> {t('back_to_store')}</PsButton>
                </div>
            </MainNav>

            <div>
                <h5>{t('sales_person')}</h5>
                {
                    userInfo ?
                        <table className="table w-100">
                            <thead className="bg-secondary text-white">
                                <tr>
                                    <th>{t('fullname')}</th>
                                    <th>{t('store_name')}</th>
                                    <th>{t('email')}</th>
                                    <th>{t('phone_number')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{userInfo.fullname}</td>
                                    <td>{userInfo.store_name}</td>
                                    <td>{userInfo.email}</td>
                                    <td>{userInfo.phone}</td>
                                </tr>
                            </tbody>
                        </table> : null
                }
                {Object.keys(errorInfo).length > 0 ?
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        <strong>{errorInfo.message}</strong>
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setErrorInfo({})}></button>
                    </div>
                    : null}

                {Object.keys(successInfo).length > 0 ?
                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                        <strong>{successInfo.message}</strong>
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessInfo({})}></button>
                        <hr />
                        <table className="table table-sm mb-0 table-striped">
                            <thead>
                                <tr>
                                    <th>{t('phone_number')}</th>
                                    <th>{t('price')}</th>
                                    <th>{t('sim_provider')}</th>
                                    <th>{t('sim_package_type')}</th>
                                    <th>{t('sim_paid_type')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {successInfo.numbers.map((item) => {
                                    return (
                                        <tr key={`success-add-phone-number-row-${item.number}`}>
                                            <td>{item.number}</td>
                                            <td>{currencyFormat(item.price)}</td>
                                            <td>{prefix.sim_provider[item.provider].provider_name}</td>
                                            <td>{prefix.sim_package_type[item.package].name}</td>
                                            <td>{prefix.sim_paid_type[item.paid].name}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    : null}

                <div className="mt-4">
                    <h5>{t("phone_number_will_add_list")}</h5>
                    {
                        formIds.map((item, index) => {
                            return (
                                <div className="d-flex bg-light mb-3 p-3" key={`form-add-phone-number-${item}`}>
                                    <div className="flex-fill">
                                        <FormAddPhoneNumber uuid={item} ref={(element) => { refs.current[index] = element }} prefix={{ simPackageType: prefix.sim_package_type, simProvider: prefix.sim_provider, simPaidType: prefix.sim_paid_type, saleStatusOption: prefix.sale_status_option }} />
                                    </div>
                                    &nbsp;
                                    <div className="pt-4 pb-2">
                                        <PsButton isDisabled={formIds.length <= 1} containerClassName="mt-2" color="danger" icon={<FaTrash />} handleClick={() => removeForm(index)}></PsButton>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <p className="text-black-50">
                    {t('add_phone_number_instruction_1')}
                </p>
                <div>
                    <PsButton handleClick={() => addNewForm()} color="secondary" isDisabled={isSubmitting}><AiOutlinePlusCircle /> {t('add_form_row')}</PsButton>
                    &nbsp;
                    <PsButton handleClick={() => submitAllForm()} color="success" isDisabled={isSubmitting}><AiOutlineSave /> {t('submit')}</PsButton>
                </div>
            </div>
        </>
    )
}
