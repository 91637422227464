import React from 'react';
import { useTranslation } from 'react-i18next';
import MainNav from '../nav/MainNav';
import PsButton from '../element/PsButton';
import { FiLogIn } from 'react-icons/fi';

export default function PageProfile() {
  const { t } = useTranslation();
  return (
    <>
    Profile
    </>
  )
}
