import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MainNav from '../nav/MainNav';
import api from '../../js/api';
import alert from '../../js/alert';
import SalesPersonInfo from './sales-person/SalesPersonInfo';
import PsButton from '../element/PsButton';
import { AiFillEdit } from 'react-icons/ai';
import { BsPlusCircleFill } from 'react-icons/bs';
import { MdOutlineCancel } from 'react-icons/md';
import SalesPersonForm from './sales-person/SalesPersonForm';
import { useDataStoreContext } from '../../context/DataStoreContext';
import { FORM_SUBMIT_TYPE } from '../../js/utilities';
import SalesPersonPhoneNumber from './sales-person/SalesPersonPhoneNumber';
import { NavLink } from 'react-router-dom';
import { useLoadingContext } from '../../context/LoadingContext';

export default function PageSalesPersonInfo() {
    const { t } = useTranslation();
    const { uid } = useParams();
    const [userInfo, setUserInfo] = useState(null);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const formRef = useRef();
    const dataStorecontext = useDataStoreContext();
    const { presentLoading, dismissLoading } = useLoadingContext();

    useEffect(() => {
        fetchUser();
    }, []);

    const fetchUser = async () => {
        try {
            presentLoading();
            const fetch = await api.get(`sales-person/${uid}`);
            setUserInfo(fetch.data);
        } catch (error) {
            alert.toastError(alert.tryGetErrorMessage(error));
        } finally {
            dismissLoading();
        }
    }

    const switchEditFormAndInfo = (state) => {
        setIsFormOpen(state);
    }

    const onSubmit = async (data) => {
        try {
            const submit = await api.put(`sales-person/${uid}`, data);
            onSuccess(submit, data);
        } catch (error) {
            alert.toastError(alert.tryGetErrorMessage(error));
        }
    }

    const onSuccess = (result, submitData) => {
        const tmpData = Object.assign(userInfo);
        for (let e in submitData) {
            tmpData[e] = submitData[e];
        }
        setUserInfo({ ...tmpData });
        dataStorecontext.updateDataRow(userInfo.id, 'id', { ...tmpData });
        alert.toastSuccess(alert.tryGetSuccessMessage(result));
        switchEditFormAndInfo(false);
    }

    return (
        <>
            <MainNav pageTitle={t('sales_person')}></MainNav>
            <div>
                {
                    userInfo !== null ?
                        <>
                            <div className="d-flex justify-content-between mb-3">
                                <h3 className="text-truncate">{userInfo.fullname}</h3>
                                <div className="d-flex">

                                    {!isFormOpen ?
                                        <>
                                            <div className="dropdown me-2">
                                                <button className="btn py-2 btn-success dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <BsPlusCircleFill /> {t('add_phone_number_for_sales_person')}
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li>
                                                        <NavLink to={{ pathname: `/sales-person/${uid}/store/phone-number/add` }} className="dropdown-item">
                                                            {t('add_phone_number_manual')}
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={{ pathname: `/sales-person/${uid}/store/phone-number/add-excel` }} className="dropdown-item">
                                                            {t('add_phone_number_excel')}
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                            <PsButton color="secondary" icon={<AiFillEdit />} handleClick={() => switchEditFormAndInfo(true)}>{t('edit')}</PsButton>
                                        </> : 
                                        <PsButton type="button" color="danger" icon={<MdOutlineCancel />} handleClick={() => { switchEditFormAndInfo(false) }}>{t('cancel')}</PsButton>}
                                </div>
                            </div>
                            {
                                !isFormOpen
                                    ? <SalesPersonInfo info={userInfo} onSubmitFormSuccess={onSuccess} />
                                    :
                                    <SalesPersonForm formControlData={userInfo} formSubmitType={FORM_SUBMIT_TYPE.UPDATE} onSubmit={onSubmit} ref={formRef} />
                            }
                            <SalesPersonPhoneNumber />
                        </> :
                        <>Not found</>
                }
            </div>

        </>
    )
}
