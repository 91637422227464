import React, { createContext, useContext, useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { console_info, console_log, console_warning } from '../js/dev';
import { sortDataBy } from '../js/utilities';

export const DataStoreContext = createContext();

function DataStoreContextProvider(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [currentDataPath, setCurrentDataPath] = useState("");
    const [currentDataLocationKey, setCurrentDataLocationKey] = useState("");
    const [dataList, setDataList] = useState([]);
    const [queryParams, setQueryParams] = useState({});
    const [dataCurrentPage, setDataCurrentPage] = useState(1);

    useEffect(() => {
        console_log(location)
    }, [location.pathname]);

    const clearDataStore = () => {
        setDataList([]);
        setQueryParams({});
    }

    const setDataStoreState = (data) => {
        console_info(`Data store context set ${location.pathname} data`)
        setCurrentDataLocationKey(location.key);
        setCurrentDataPath(location.pathname);
        setDataCurrentPage(1);
        setDataList(data);
    }

    const hasDataStore = () => {
        return location.pathname === currentDataPath && location.key === currentDataLocationKey;
    }

    const updateDataRow = (id, byKey, data) => {
        if(!location.pathname.startsWith(currentDataPath)){
            console_warning(`Data path to update is not relate!`);
            return;
        }
        const i = findDataIndex(id, byKey);
        if(i === -1){
            console_warning(`Can not find data index!`);
            return;
        }
        const u = [...dataList];
        u[i] = data;
        setDataList(u);
    }

    const insertDataRow = async (data) => {
        if(!location.pathname.startsWith(currentDataPath)){
            console_warning(`Data path to update is not relate!`);
            return;
        }
        const u = [...dataList];
        u.unshift(data);
        setDataList(u);
    }

    const deleteDataRow = async (index) => {
        if(!location.pathname.startsWith(currentDataPath)){
            console_warning(`Data path to update is not relate!`);
            return;
        }
        
        const u = [...dataList];
        const i = findDataIndex(index, 'id');
        u.splice(i, 1);
        await setDataList([...u]);
        return u;
    }

    const findDataIndex = (id, byKey) => {
        let n = -1;
        for (let i = 0; i < dataList.length; i++) {
            if(dataList[i][byKey] === id){
                n = i;
                break;
            }
        }
        return n;
    }

    const value = { dataList, setDataList, hasDataStore, setDataStoreState, clearDataStore, dataCurrentPage, setDataCurrentPage, updateDataRow, insertDataRow, deleteDataRow, queryParams, setQueryParams };

    return (
        <DataStoreContext.Provider value={value}>
            {props.children}
        </DataStoreContext.Provider>
    )
}

function useDataStoreContext() {
    const context = useContext(DataStoreContext)
    if (context === undefined) {
        throw new Error('useDataStoreContext must be used within a DataStoreProvider')
    }
    return context
}

export { useDataStoreContext }

export default DataStoreContextProvider;