import React from 'react';
import { AiOutlineCopyrightCircle } from 'react-icons/ai';

export default function MainFooter() {
  return (
    <footer>
        <div className="container-fluid py-3 bg-light small text-black-50">
            Copryright  <AiOutlineCopyrightCircle /> 2022 {process.env.REACT_APP_NAME}
        </div>
    </footer>
  )
}
