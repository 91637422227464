import React, { useState, useRef, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import MainNav from '../nav/MainNav';
import DataTable from 'react-data-table-component';
import PsButton from '../element/PsButton';
import api from '../../js/api';
import { useLoadingContext } from '../../context/LoadingContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { usePrefixContext } from '../../context/PrefixContext';
import { currencyFormat, dateDefaultFormat } from '../../js/utilities';
import { BsSearch } from 'react-icons/bs';
import { FaSignal, FaPhoneAlt, FaWallet, FaTrash } from 'react-icons/fa';
import { AiFillEdit, AiOutlineSave } from 'react-icons/ai';
import { MdWifiCalling3 } from 'react-icons/md';
import PhoneNumberFormat from '../element/PhoneNumberFormat';
import FormAddPhoneNumber from './phone-number/FormAddPhoneNumber';
import FormPhoneNumberRecommended from './phone-number/FormPhoneNumberRecommended';
import alert from '../../js/alert';
import { NavLink } from 'react-router-dom';
import { useDataStoreContext } from '../../context/DataStoreContext';
import { console_log } from '../../js/dev';
import { RiAuctionFill } from 'react-icons/ri';

export default function PagePhoneNumber() {
    const { t } = useTranslation();
    const dataStoreContext = useDataStoreContext();
    const mySwal = withReactContent(Swal);
    const formRef = useRef();
    const { presentLoading, dismissLoading } = useLoadingContext();
    const [isFetching, setIsFetching] = useState(false);
    const [datatableList, setDatatableList] = useState([]);
    const { prefix, pnConfig, getPnConfig } = usePrefixContext();
    const [filterText, setFilterText] = useState("");
    const [filterProvider, setFilterProvider] = useState(0);
    const [filterPackage, setFilterPackage] = useState(0);
    const [filterPaid, setFilterPaid] = useState(0);
    const [filterSaleStatus, setFilterSaleStatus] = useState(1);
    // const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    // const filteredItems = dataList.filter(
    //     (item) => {
    //         return (
    //             (item.provider_id && (filterProvider > 0 ? item.provider_id === filterProvider : item.provider_id)) &&
    //             (item.number && item.number.includes(filterText.toLowerCase())) &&
    //             (item.package_type && (filterPackage > 0 ? item.package_type === filterPackage : item.package_type)) &&
    //             (item.paid_type && (filterPaid > 0 ? item.paid_type === filterPaid : item.paid_type))
    //         )
    //     },
    // );

    useEffect(() => {
        getPnConfig();
        const hasData = dataStoreContext.hasDataStore();
        console_log(hasData)
        if (hasData) {
            setDatatableList(dataStoreContext.dataList);
            setFilterText(dataStoreContext.queryParams.number);
            setFilterProvider(dataStoreContext.queryParams.provider);
            setFilterPackage(dataStoreContext.queryParams.package);
            setFilterPaid(dataStoreContext.queryParams.paid);
            setFilterSaleStatus(dataStoreContext.queryParams.sale_status);
        }
    }, []);

    // const subHeaderComponentMemo = useMemo(() => {
    //     const handleClear = () => {
    //         if (filterText) {
    //             setResetPaginationToggle(!resetPaginationToggle);
    //             setFilterText("");
    //             setFilterProvider(0);
    //             setFilterPackage(0);
    //             setFilterPaid(0);
    //         }
    //     };

    //     return (
    //         <div className="row gx-1 flex-fill">
    //             <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2">
    //                 <label className="form-label" htmlFor="phone_number"><BsSearch /> {t('phone_number')}</label>
    //                 <input
    //                     id="phone_number"
    //                     type="text"
    //                     placeholder={t('phone_number')}
    //                     aria-label="Search Input"
    //                     value={filterText}
    //                     onChange={e => setFilterText(e.target.value)}
    //                     className="form-control"
    //                 />
    //             </div>

    //             <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2">
    //                 <label className="form-label" htmlFor="sim-provider"><FaSignal /> {t('sim_provider')}</label>
    //                 <select name="sim-provider" id="sim-provider" className="form-select" value={filterProvider} onChange={(e) => setFilterProvider(parseInt(e.target.value))}>
    //                     <option value={0}>{t('all')}</option>
    //                     {Object.entries(prefix.sim_provider).map(([key, value]) => {
    //                         return (<option value={value.id} key={`sim-provider-opt-${value.id}`}>{value.provider_name}</option>)
    //                     })}
    //                 </select>
    //             </div>

    //             <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2">
    //                 <label className="form-label" htmlFor="sim-package-type"><MdWifiCalling3 /> {t('sim_package_type')}</label>
    //                 <select name="sim-package-type" id="sim-package-type" className="form-select" value={filterPackage} onChange={(e) => setFilterPackage(parseInt(e.target.value))}>
    //                     <option value={0}>{t('all')}</option>
    //                     {Object.entries(prefix.sim_package_type).map(([key, value]) => {
    //                         return (<option value={value.id} key={`sim-package-type-opt-${value.id}`}>{value.name}</option>)
    //                     })}
    //                 </select>
    //             </div>
    //             <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2">
    //                 <label className="form-label" htmlFor="sim-paid-type"><FaWallet /> {t('sim_paid_type')}</label>
    //                 <select name="sim-paid-type" id="sim-paid-type" className="form-select" value={filterPaid} onChange={(e) => setFilterPaid(parseInt(e.target.value))}>
    //                     <option value={0}>{t('all')}</option>
    //                     {Object.entries(prefix.sim_paid_type).map(([key, value]) => {
    //                         return (<option value={value.id} key={`sim-paid-type-opt-${value.id}`}>{value.name}</option>)
    //                     })}
    //                 </select>
    //             </div>
    //             <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2 mt-md-4 pt-md-2 mt-lg-0 pt-lg-0 mt-xl-4 pt-xl-2">
    //                 <PsButton handleClick={handleClear} icon={<BsSearch />}>{t('search')}</PsButton>
    //             </div>
    //         </div>
    //     );
    // }, [filterText, filterProvider, filterPackage, filterPaid, resetPaginationToggle]);

    const fetchDataList = async () => {
        try {
            setIsFetching(true);
            const params = {
                number: filterText,
                provider: filterProvider,
                paid: filterPaid,
                package: filterPackage,
                sale_status: filterSaleStatus,
            };
            const fetch = await api.get(`phone-number`, { params: params });
            setDatatableList(fetch.data);
            dataStoreContext.setQueryParams(params);
            dataStoreContext.setDataStoreState(fetch.data);
        } catch (error) {
        } finally {
            setIsFetching(false);
        }
    }

    const submitSearch = () => {
        fetchDataList();
    }

    const onChangePage = (p) => {
        dataStoreContext.setDataCurrentPage(p);
    }

    const columns = [
        {
            name: t('phone_number'),
            selector: row => <PhoneNumberFormat number={row.number} />,
            minWidth: '120px'
        },
        {
            name: t('sales_person'),
            selector: row => row.sales_id,
            cell: row => (
                <NavLink to={{ pathname: `/sales-person/${row.sales_id}` }} className="text-decoration-none">
                    <div>{row.sales_name}</div>
                    <div className="small">({row.sales_email})</div>
                </NavLink>
            )
        },
        {
            name: t('sim_provider'),
            selector: row => row.provider_name,
        },
        {
            name: t('price'),
            selector: row => currencyFormat(row.price),
        },
        {
            name: t('store_price'),
            selector: row => (<div className="text-success">{currencyFormat(row.store_price)}</div>),
        },
        {
            name: t('sim_package_type'),
            selector: row => prefix.sim_package_type[row.package_type].name,
        },
        {
            name: t('phone_number_sum'),
            selector: row => row.number_sum,
            maxWidth: '50px'
        },
        {
            name: t('sim_paid_type'),
            selector: row => prefix.sim_paid_type[row.paid_type].name,
        },
        {
            name: t('phone_number_sale_status'),
            selector: row => row.sale_status,
            cell: row => prefix.sale_status_option[row.sale_status].name,
        },
        {
            name: t('phone_number_recommended'),
            selector: row => row.recommended_end_date,
            cell: row => (
                <>
                    {row.sale_status === 1 &&
                        <div className="py-1">
                            {row.recommended_end_date !== null ?
                                <><span className="small">{t('phone_number_recommended_until', { date: dateDefaultFormat(row.recommended_end_date) })}&nbsp;</span></>
                                : null}
                            <PsButton color="info" size="sm" handleClick={() => editPhoneNumberRecommended(row)}><AiFillEdit /></PsButton>&nbsp;&nbsp;
                        </div>
                    }
                </>
            )
        },
        {
            name: t('edit'),
            selector: row => row.id,
            cell: row => (
                <div>
                    <PsButton color="secondary" containerClassName="my-1" handleClick={() => editPhoneNumber(row)}><AiFillEdit /></PsButton>&nbsp;&nbsp;
                    <PsButton color="danger" containerClassName="my-1" handleClick={() => deletePhoneNumber(row)}><FaTrash /></PsButton>
                </div>
            )
        },
    ];

    const editPhoneNumberRecommended = async (row) => {
        mySwal.fire(
            {
                title: t('edit'),
                html: <FormPhoneNumberRecommended uuid={row.id} ref={formRef} prefix={{ simPackageType: prefix.sim_package_type, simProvider: prefix.sim_provider, simPaidType: prefix.sim_paid_type, saleStatusOption: prefix.sale_status_option }} />,
                showCloseButton: true,
                confirmButtonText: <><AiOutlineSave /> {t('submit')}</>,
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-success',
                    // popup: 'swal-lg',
                },
                preConfirm: async () => {
                    const uuid = formRef.current.uuid;
                    const recommendedAmount = formRef.current.recommendedAmount;

                    try {
                        presentLoading();
                        const formData = [{
                            key: uuid,
                            recommended_amount: recommendedAmount,
                        }];
                        const submit = await api.put(`sales-person/${row.sales_id}/phone-number/recommended`, formData);
                        onUpdateRecommendedSuccess(submit.data, row, formData);
                    } catch (error) {
                        mySwal.showValidationMessage(alert.tryGetErrorMessage(error));
                    } finally {
                        dismissLoading();
                    }
                },
                didOpen: () => {
                    formRef.current.setIsEditMode(true);
                    formRef.current.setNumbers(row.number);
                    formRef.current.setPrice(row.price);
                    formRef.current.setProvider(row.provider_id);
                    formRef.current.setPaidType(row.paid_type);
                    formRef.current.setPackageType(row.package_type);
                    formRef.current.setSaleStatus(row.sale_status);
                }
            }
        ).then((result) => {
            return false
        });
    }

    const onUpdateRecommendedSuccess = (result, row, formData) => {
        alert.toastSuccess(alert.tryGetSuccessMessage(result));
        const tmp = datatableList;
        for (let j = 0; j < formData.length; j++) {
            let index = -1;
            for (let i = 0; i < datatableList.length; i++) {
                if (datatableList[i].id === formData[j].key) {
                    index = i;
                    break;
                }
            }
            if (index < 0) {
                continue;
            }
            tmp[index].recommended_end_date = result[formData[j].key];
        }

        setDatatableList([...tmp]);
    }

    const editPhoneNumber = async (row) => {
        mySwal.fire(
            {
                title: t('edit'),
                html: <FormAddPhoneNumber uuid={row.id} ref={formRef} prefix={{ simPackageType: prefix.sim_package_type, simProvider: prefix.sim_provider, simPaidType: prefix.sim_paid_type, saleStatusOption: prefix.sale_status_option }} />,
                showCloseButton: true,
                confirmButtonText: <><AiOutlineSave /> {t('submit')}</>,
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-success',
                    popup: 'swal-lg',
                },
                preConfirm: async () => {
                    const uuid = formRef.current.uuid;
                    const price = parseFloat(formRef.current.price);
                    const packageType = formRef.current.packageType;
                    const paid = formRef.current.paidType;
                    const provider = formRef.current.provider;
                    const numbers = formRef.current.numbers.trim();
                    const saleStatus = formRef.current.saleStatus;

                    if (numbers.length === 0) {
                        mySwal.showValidationMessage(t('please_enter_field_value'));
                        return false;
                    }

                    if (price < pnConfig.pn_min_price) {
                        mySwal.showValidationMessage(t('invalid_price_min', { min_price: pnConfig.pn_min_price }));
                        return false;
                    }

                    try {
                        presentLoading();
                        const formData = [{
                            key: uuid,
                            numbers: numbers,
                            package: packageType,
                            paid: paid,
                            provider: provider,
                            price: price,
                            sale_status: saleStatus,
                        }];
                        const submit = await api.put(`sales-person/${row.sales_id}/phone-number`, formData);
                        onUpdateSuccess(submit.data, row, formData);
                    } catch (error) {
                        mySwal.showValidationMessage(alert.tryGetErrorMessage(error));
                    } finally {
                        dismissLoading();
                    }
                },
                didOpen: () => {
                    formRef.current.setIsEditMode(true);
                    formRef.current.setNumbers(row.number);
                    formRef.current.setPrice(row.price);
                    formRef.current.setProvider(row.provider_id);
                    formRef.current.setPaidType(row.paid_type);
                    formRef.current.setPackageType(row.package_type);
                    formRef.current.setSaleStatus(row.sale_status);
                }
            }
        ).then((result) => {
            return false
        });
    }

    const onUpdateSuccess = (result, row, formData) => {
        alert.toastSuccess(alert.tryGetSuccessMessage(result));
        const tmp = datatableList;
        for (let j = 0; j < formData.length; j++) {
            let index = -1;
            for (let i = 0; i < datatableList.length; i++) {
                if (datatableList[i].id === formData[j].key) {
                    index = i;
                    break;
                }
            }

            if (index < 0) {
                return;
            }
            tmp[index].price = formData[j].price;
            tmp[index].package_type = formData[j].package;
            tmp[index].paid_type = formData[j].paid;
            tmp[index].provider_id = formData[j].provider;
            tmp[index].provider_name = prefix.sim_provider[formData[j].provider].provider_name;
            tmp[index].sale_status = formData[j].sale_status;
        }

        setDatatableList([...tmp]);
    }

    const deletePhoneNumber = async ({ id, number, sales_id }) => {
        mySwal.fire({
            title: <p>{t('delete_data')}</p>,
            html: (<p>
                <Trans components={{ hilight1: <span className="fw-bold" /> }}>
                    {t('delete_phone_number_confirm', { number: number })}
                </Trans>
            </p>),
            icon: 'question',
            showCloseButton: true,
            confirmButtonText: (<><FaTrash /> {t('delete')}</>),
            buttonsStyling: false,
            customClass: {
                confirmButton: 'btn btn-danger',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                deletePhoneNumberProcess(id, sales_id);
            }
        });
    }

    const deletePhoneNumberProcess = async (id, sales_id) => {
        try {
            presentLoading();
            const submit = await api.delete(`sales-person/${sales_id}/phone-number`, { data: { 'id': id } });
            alert.toastSuccess(alert.tryGetSuccessMessage(submit));

            let index = -1;
            for (let i = 0; i < datatableList.length; i++) {
                if (datatableList[i].id === id) {
                    index = i;
                    break;
                }
            }

            if (index < 0) {
                return;
            }

            const tmp = datatableList;
            tmp.splice(index, 1);
            setDatatableList([...tmp]);
        } catch (error) {
            alert.toastError(alert.tryGetErrorMessage(error));
        } finally {
            dismissLoading();
        }
    }


    return (
        <>
            <MainNav pageTitle={t('phone_number_for_sale')}></MainNav>

            <form onSubmit={(e) => e.preventDefault()}>
                <fieldset>
                    <div className="row gx-1 flex-fill">
                        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2">
                            <label className="form-label" htmlFor="phone_number"><FaPhoneAlt /> {t('phone_number')}</label>
                            <input
                                id="phone_number"
                                type="text"
                                placeholder={t('phone_number')}
                                aria-label="Search Input"
                                value={filterText}
                                onChange={e => setFilterText(e.target.value)}
                                className="form-control"
                            />
                        </div>

                        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2">
                            <label className="form-label" htmlFor="sim-provider"><FaSignal /> {t('sim_provider')}</label>
                            <select name="sim-provider" id="sim-provider" className="form-select" value={filterProvider} onChange={(e) => setFilterProvider(parseInt(e.target.value))}>
                                <option value={0}>{t('all')}</option>
                                {Object.entries(prefix.sim_provider).map(([key, value]) => {
                                    return (<option value={value.id} key={`sim-provider-opt-${value.id}`}>{value.provider_name}</option>)
                                })}
                            </select>
                        </div>

                        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2">
                            <label className="form-label" htmlFor="sim-package-type"><MdWifiCalling3 /> {t('sim_package_type')}</label>
                            <select name="sim-package-type" id="sim-package-type" className="form-select" value={filterPackage} onChange={(e) => setFilterPackage(parseInt(e.target.value))}>
                                <option value={0}>{t('all')}</option>
                                {Object.entries(prefix.sim_package_type).map(([key, value]) => {
                                    return (<option value={value.id} key={`sim-package-type-opt-${value.id}`}>{value.name}</option>)
                                })}
                            </select>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2">
                            <label className="form-label" htmlFor="sim-paid-type"><FaWallet /> {t('sim_paid_type')}</label>
                            <select name="sim-paid-type" id="sim-paid-type" className="form-select" value={filterPaid} onChange={(e) => setFilterPaid(parseInt(e.target.value))}>
                                <option value={0}>{t('all')}</option>
                                {Object.entries(prefix.sim_paid_type).map(([key, value]) => {
                                    return (<option value={value.id} key={`sim-paid-type-opt-${value.id}`}>{value.name}</option>)
                                })}
                            </select>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2">
                            <label className="form-label" htmlFor="sale-status"><RiAuctionFill /> {t('phone_number_sale_status')}</label>
                            <select name="sale-status" id={`sale-status`} className="form-select" value={filterSaleStatus} onChange={(e) => setFilterSaleStatus(parseInt(e.target.value))}>
                                <option value={0}>{t('all')}</option>
                                {Object.entries(prefix.sale_status_option).map(([key, value]) => {
                                    return (<option value={value.id} key={`sale-status-type-opt-${value.id}`}>{value.name}</option>)
                                })}
                            </select>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2 mt-4 pt-2">
                            <PsButton type="submit" icon={<BsSearch />} handleClick={submitSearch}>{t('search')}</PsButton>
                        </div>
                    </div>
                </fieldset>
            </form>

            <div className="">
                <DataTable
                    columns={columns}
                    // data={filteredItems}
                    data={datatableList}
                    pagination
                    striped
                    paginationDefaultPage={dataStoreContext.hasDataStore() ? dataStoreContext.dataCurrentPage : 1}
                    onChangePage={onChangePage}
                    paginationComponentOptions={{
                        noRowsPerPage: true
                    }}
                // subHeader
                // subHeaderComponent={subHeaderComponentMemo}
                />
            </div>
        </>
    )
}



