import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MainNav from '../nav/MainNav';
import api from '../../js/api';
import alert from '../../js/alert';
import PsButton from '../element/PsButton';
import { AiFillEdit } from 'react-icons/ai';
import { useDataStoreContext } from '../../context/DataStoreContext';
import FaqInfo from './faq/FaqInfo';
import FaqForm from './faq/FaqForm';
import { FORM_SUBMIT_TYPE } from '../../js/utilities';
import { MdOutlineCancel } from 'react-icons/md';

export default function PageFaqInfo() {
    const { t } = useTranslation();
    const { id } = useParams();
    const [faqInfo, setFaqInfo] = useState(null);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const formRef = useRef();
    const dataStorecontext = useDataStoreContext();

    useEffect(() => {
        fetchFaq();
    }, []);

    const fetchFaq = async () => {
        try {
            const fetch = await api.get(`faq/${id}`, { loading: true });
            setFaqInfo(fetch.data);
        } catch (error) {
            alert.toastError(alert.tryGetErrorMessage(error));
        }
    }

    const onSubmit = async (data) => {
        try {
            const submit = await api.put(`faq/${id}`, data);
            onSuccess(submit, data);
        } catch (error) {
            alert.toastError(alert.tryGetErrorMessage(error));
        } finally {
        }
    }

    const switchEditFormAndInfo = (state) => {
        setIsFormOpen(state);
    }

    const onSuccess = (result, submitData) => {
        const tmpData = Object.assign(faqInfo);
        for (let e in submitData) {
            tmpData[e] = submitData[e];
        }
        setFaqInfo({ ...tmpData });
        dataStorecontext.updateDataRow(faqInfo.id, 'id', { ...tmpData });
        alert.toastSuccess(alert.tryGetSuccessMessage(result));
        switchEditFormAndInfo(false);
    }

    return (
        <>
            <MainNav pageTitle={t('faq')}></MainNav>
            <div>
                {
                    faqInfo !== null ?
                        <>
                            <div className="d-flex justify-content-between mb-3">
                                <div></div>
                                {!isFormOpen && faqInfo !== null ? <PsButton color="secondary" icon={<AiFillEdit />} handleClick={() => switchEditFormAndInfo(true)}>{t('edit')}</PsButton> : <PsButton type="button" color="danger" icon={<MdOutlineCancel />} handleClick={() => {switchEditFormAndInfo(false)}}>{t('cancel')}</PsButton>}
                            </div>
                            {
                                !isFormOpen
                                    ? <FaqInfo info={faqInfo} />
                                    : <FaqForm formControlData={faqInfo} formSubmitType={FORM_SUBMIT_TYPE.UPDATE} onSubmit={onSubmit} ref={formRef} />
                            }
                        </> :
                        <>Not found</>
                }
            </div>

        </>
    )
}
