import React from 'react';
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent, SidebarFooter } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { useTranslation } from 'react-i18next';
import { FaHome, FaUserCircle, FaSignOutAlt, FaUserFriends } from 'react-icons/fa';
import { HiChevronDoubleRight, HiChevronDoubleLeft } from 'react-icons/hi';
import { MdPhoneIphone } from 'react-icons/md';
import { IoMdChatbubbles } from 'react-icons/io';
import { RiContactsBookFill } from 'react-icons/ri';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useAuthContext } from './../../context/AuthContext';
import { useLayoutContext } from '../../context/LayoutContext';
import './../../css/nav.css';

export default function MainSidebar(props) {
    const { t } = useTranslation();
    const authContext = useAuthContext();
    const layoutcontext = useLayoutContext();
    const MySwal = withReactContent(Swal)

    const logOut = () => {
        MySwal.fire({
            title: <p>{t('logout')}</p>,
            html: (<p>{t('logout_confirm')}</p>),
            icon: 'question',
            showCloseButton: true,
            confirmButtonText: (<><FaSignOutAlt /> {t('logout')}?</>),
            buttonsStyling: false,
            customClass: {
                confirmButton: 'btn btn-danger',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                authContext.logOut();
            }
        });
    }

    return (
        <ProSidebar
            breakPoint="md"
            collapsed={layoutcontext.sidebarCollapsed}
            toggled={layoutcontext.sidebarToggle}
            onToggle={layoutcontext.toggleSidebarHandler}
            ref={layoutcontext.sidebarRef}>
            <SidebarHeader>
                <div className="header">
                    {authContext.activeUser.info.username}
                </div>
            </SidebarHeader>
            <SidebarContent>
                <Menu iconShape="square">

                    <MenuItem icon={<FaHome />}>
                        <NavLink to={`/`}>
                            {t('home')}
                        </NavLink>
                    </MenuItem>

                    <SubMenu title={t('sales_person')} icon={<FaUserFriends />}>
                        <MenuItem >
                            <NavLink to={`/sales-person`} end>
                                {t('search')}
                            </NavLink>
                        </MenuItem>
                        <MenuItem>
                            <NavLink to={`/sales-person/add`}>
                                {t('add')}
                            </NavLink>
                        </MenuItem>
                    </SubMenu>

                    <SubMenu title={t('phone_number_for_sale')} icon={<MdPhoneIphone />}>
                        <MenuItem >
                            <NavLink to={`/phone-number`} end>
                                {t('search')}
                            </NavLink>
                        </MenuItem>

                        <MenuItem >
                            <NavLink to={`/phone-number/add`} end>
                                {t('add_phone_number_for_sales_person')}
                            </NavLink>
                        </MenuItem>
                      
                        {/* <SubMenu title={t('add_phone_number_for_sales_person')}>
                            <MenuItem >
                                <NavLink to={`/phone-number/add/manual`}>
                                    {t('add_phone_number_manual')}
                                </NavLink>
                            </MenuItem>
                            <MenuItem>
                                <NavLink to={`/phone-number/add/excel`}>
                                    {t('add_phone_number_excel')}
                                </NavLink>
                            </MenuItem>
                        </SubMenu> */}
                        <MenuItem >
                            <NavLink to={`/phone-number/search/logs`}>
                                {t('search_phone_number_logs')}
                            </NavLink>
                        </MenuItem>
                        <MenuItem >
                            <NavLink to={`/phone-number/config`}>
                                {t('set_phone_number_config')}
                            </NavLink>
                        </MenuItem>
                    </SubMenu>

                    <SubMenu title={t('faq')} icon={<IoMdChatbubbles />}>
                        <MenuItem >
                            <NavLink to={`/faq`} end>
                                {t('search')}
                            </NavLink>
                        </MenuItem>
                        <MenuItem>
                            <NavLink to={`/faq/add`}>
                                {t('add')}
                            </NavLink>
                        </MenuItem>
                    </SubMenu>

                    <SubMenu title={t('contact_info')} icon={<RiContactsBookFill />}>
                        <MenuItem>
                            <NavLink to={`/web-contact`}>
                                {t('edit')}
                            </NavLink>
                        </MenuItem>
                    </SubMenu>
                    <SubMenu title={t('profile')} icon={<FaUserCircle />}>
                        <MenuItem>
                            <NavLink to={`/profile/change-password`}>
                                {t('change_password')}
                            </NavLink>
                        </MenuItem>
                    </SubMenu>
                    <MenuItem icon={<FaSignOutAlt />} onClick={() => { logOut(); }}>
                        {t('logout')}
                    </MenuItem>
                </Menu>
            </SidebarContent>
            <Menu iconShape="square">
                <MenuItem icon={layoutcontext.sidebarCollapsed ? <HiChevronDoubleRight /> : <HiChevronDoubleLeft />} onClick={() => { layoutcontext.collapsedSidebarHandler(); }}>
                    {t('collapse_sidebar')}
                </MenuItem>
            </Menu>
            <SidebarFooter>

            </SidebarFooter>
        </ProSidebar>
    )
}
