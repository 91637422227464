import React, { useState, useRef, useImperativeHandle, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import WYSIWYGEditor from '../../form/WYSIWYGEditor';
import HookFormItems from '../../form/HookFormItems';
import PsButton from '../../element/PsButton';
import { RiSave3Fill } from 'react-icons/ri';
import alert from '../../../js/alert';
import { FORM_SUBMIT_TYPE } from '../../../js/utilities';

const FaqForm = forwardRef((props, ref) => {

    const { t } = useTranslation();
    const editorRef = useRef();
    const [faqContent, setFaqContent] = useState("");
    const { register, control, handleSubmit, setValue, reset, formState: { errors, isDirty, isValid, isSubmitted }, } = useForm({ reValidateMode: 'onChange', });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorInfo, setErrorInfo] = useState({});

    useImperativeHandle(ref, () => ({
        handleSubmit: handleSubmit(onSubmit, onError),
        errors: errors,
        isDirty: isDirty,
        isValid: isValid,
        isSubmitting: isSubmitting,
        resetForm: resetForm,
        errorInfo: errorInfo
    }));

    const setFaqAnswer = (content) => {
        setValue('answer', content);
    }

    const inputFields = [
        {
            label: t('question'),
            requiredOptions: {
                required: t('please_enter_field_value'),
            },
            attr: {
                name: "question",
                type: "text",
                inputMode: "text",
                placeholder: t('question'),
                id: "form-faq-question-field",
                autoComplete: "off",
            },
            defaultValue: props.formSubmitType === FORM_SUBMIT_TYPE.UPDATE ? props.formControlData.question : undefined
        },
        {
            label: t('answer'),
            requiredOptions: {
                required: t('please_enter_field_value'),
                minLength: {
                    value: 10,
                    message: t('text_too_short_warning')
                }
            },
            attr: {
                name: "answer",
                type: "textarea",
                inputMode: undefined,
                placeholder: t('answer'),
                id: "form-answer-content-field",
                autoComplete: "off",
                value: faqContent,
                hidden: true
            },
            defaultValue: props.formSubmitType === FORM_SUBMIT_TYPE.UPDATE ? props.formControlData.answer : undefined,
            callback: {
                onChange: (e) => {
                    setFaqContent(e.target.value);
                }
            }
        }
    ];

    const onSubmit = async (data) => {
        const isAnswerEmpty = data.answer.trim().length === 0;
        if (isAnswerEmpty) {
            return;
        }
        try {
            setErrorInfo({});
            setIsSubmitting(true);
            const submit = await props.onSubmit(data);
        } catch (error) {
            onError(error);
        } finally {
            setIsSubmitting(false);
        }
    }

    const onError = (err) => {
        const errMessage = alert.tryGetErrorMessage(err);
        if (props.onError) {
            props.onError({ message: errMessage });
        }
        setErrorInfo({ message: errMessage });
        throw new Error(errMessage);
    }

    const resetForm = () => {
        editorRef.current.resetEditor();
        // setValue('question', "");
        // setValue('answer', "");
        reset();
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit, onError)} id="form-login" autoComplete="off">
                <fieldset disabled={isSubmitting}>
                    <div className="row my-0">
                        <HookFormItems formFields={inputFields} register={register} control={control} errors={errors} isDirty={isDirty} isValid={isValid} />
                        <div className="col-12">
                            <label className="form-label">{t('answer')} <span className="text-danger">*</span></label>
                            <WYSIWYGEditor ref={editorRef} onContentChange={setFaqAnswer} defaultValue={props.formSubmitType === FORM_SUBMIT_TYPE.UPDATE ? props.formControlData.answer : undefined} />
                            {errors['answer'] ? <div className="form-text text-danger">{errors['answer'].message}</div> : null}
                            {/* {faqError !== "" && (isDirty || isSubmitted) && isFormSubmitted === true ? <div className="form-text text-danger">{faqError}</div> : ""} */}
                        </div>
                        <div className="col-12">
                            <PsButton type="submit" containerClassName="mt-3" icon={<RiSave3Fill />} isLoading={isSubmitting}>{t('submit')}</PsButton>
                        </div>
                    </div>
                </fieldset>
            </form>
        </>
    )
})

export default FaqForm