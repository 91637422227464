import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiUnlock, FiEye, FiEyeOff } from 'react-icons/fi';
import HookFormItems from '../form/HookFormItems';
import PsButton from '../element/PsButton';
import { useAuthContext } from '../../context/AuthContext';
import MainNav from '../nav/MainNav';
import api from '../../js/api';
import alert from '../../js/alert';

export default function PageProfileChangePassword(props) {
  const { t } = useTranslation();
  const authContext = useAuthContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { register, control, handleSubmit, reset, formState: { errors, isDirty, isValid }, } = useForm({ reValidateMode: 'onChange', });
  const [passwordFieldType, setPasswordFieldType] = useState("");
  const [confirmPasswordFieldType, setConfirmPasswordFieldType] = useState("");
  const [currentPasswordFieldType, setCurrentPasswordFieldType] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");

  const [errorInfo, setErrorInfo] = useState({});

  const inputFields = [
    {
      label: t('new_password'),
      requiredOptions: {
        required: t('please_enter_field_value'),
        minLength: {
          value: 6,
          message: t('password_format_length_warning')
        },
        maxLength: {
          value: 60,
          message: t('password_format_length_warning')
        }
      },
      attr: {
        name: "password",
        type: passwordFieldType,
        inputMode: undefined,
        placeholder: t('new_password'),
        id: "form-password-field",
        autoComplete: "off",
      },
      controlTypeToggle: {
        a: "password",
        b: "text",
        refState: passwordFieldType,
        setRefState: setPasswordFieldType,
        aIcon: <FiEye />,
        bIcon: <FiEyeOff />
      },
      callback: {
        onChange: (e) => {
          setPassword(e.target.value);
        }
      }
    },
    {
      label: t('confirm_password'),
      requiredOptions: {
        required: t('please_enter_field_value'),
        minLength: {
          value: 6,
          message: t('password_format_length_warning')
        },
        maxLength: {
          value: 60,
          message: t('password_format_length_warning')
        },
        pattern: {
          value: new RegExp(`^${password}$`),
          message: t('password_not_match')
        }
      },
      attr: {
        name: "confirm_password",
        type: confirmPasswordFieldType,
        inputMode: undefined,
        placeholder: t('confirm_password'),
        id: "form-confirm-password-field",
        autoComplete: "off",
      },
      controlTypeToggle: {
        a: "password",
        b: "text",
        refState: confirmPasswordFieldType,
        setRefState: setConfirmPasswordFieldType,
        aIcon: <FiEye />,
        bIcon: <FiEyeOff />
      },
      callback: {
        onChange: (e) => {
          setConfirmPassword(e.target.value);
        }
      }
    },
    {
      label: t('current_password'),
      requiredOptions: {
        required: t('please_enter_field_value'),
        // minLength: {
        //   value: 6,
        //   message: t('password_format_length_warning')
        // },
      },
      attr: {
        name: "current_password",
        type: currentPasswordFieldType,
        inputMode: undefined,
        placeholder: t('current_password'),
        id: "form-current-password-field",
        autoComplete: "off",
      },
      controlTypeToggle: {
        a: "password",
        b: "text",
        refState: currentPasswordFieldType,
        setRefState: setCurrentPasswordFieldType,
        aIcon: <FiEye />,
        bIcon: <FiEyeOff />
      },
      callback: {
        onChange: (e) => {
          setCurrentPassword(e.target.value);
        }
      }
    }
  ];

  const onSubmit = async (data) => {
    try {
      setErrorInfo({});
      setIsSubmitting(true);
      const submit = await api.post(`change-password`, data);
      alert.toastSuccess(alert.tryGetSuccessMessage(submit));
      reset();
      setPassword("");
      setConfirmPassword("");
      setCurrentPassword("");
    } catch (error) {
      // alert.toastError(alert.tryGetErrorMessage(error));
      onError(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const onError = (err) => {
    const errMessage = alert.tryGetErrorMessage(err);
    setErrorInfo({ message: errMessage });
    throw new Error(errMessage);
  }

  const ErrorMessage = () => {
    return (<>{
      errorInfo.message ?
        <div className="row mt-3">
          <div className="col-12">
            <div className="alert alert-danger m-0" role="alert">
              {errorInfo.message}
            </div>
          </div>
        </div>
        : null
    }</>)
  }

  return (
    <>
      <MainNav pageTitle={t('change_password')} />
      <div className="row">
        <div className="col-lg-6">
          <form onSubmit={handleSubmit(onSubmit, onError)} id="form-login" autoComplete="off">
            <fieldset disabled={isSubmitting}>
              <div className="row m-0">
                <HookFormItems formFields={inputFields} register={register} control={control} errors={errors} isDirty={isDirty} isValid={isValid} />
                <div className="col-12">
                  <PsButton type="submit" icon={<FiUnlock />} isLoading={isSubmitting}>{t('change_password')}</PsButton>
                </div>
                <ErrorMessage />
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </>
  )
}
