import React, { useState, useRef, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import MainNav from '../nav/MainNav';
import DataTable from 'react-data-table-component';
import PsButton from '../element/PsButton';
import api from '../../js/api';
import { useLoadingContext } from '../../context/LoadingContext';
import { usePrefixContext } from '../../context/PrefixContext';
import { BsSearch } from 'react-icons/bs';
import { useDataStoreContext } from '../../context/DataStoreContext';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { pad, dateDefaultFormat, dateTimeDefaultFormat, dateTimeParameterFormat, currencyFormat } from '../../js/utilities';
import CsvDownload from 'react-csv-downloader';
import { RiFileExcel2Fill } from 'react-icons/ri';

export default function PagePhoneNumberSearchLogs() {
    const { t } = useTranslation();
    // const dataStoreContext = useDataStoreContext();
    const { presentLoading, dismissLoading } = useLoadingContext();
    const [isFetching, setIsFetching] = useState(false);
    const [datatableList, setDatatableList] = useState([]);
    const { prefix } = usePrefixContext();

    const today = new Date();
    const todayStr = today.getFullYear() + "/" + pad(today.getMonth() + 1) + "/" + pad(today.getDate());

    const [startDate, setStartDate] = useState(new Date(todayStr));
    const [endDate, setEndDate] = useState(new Date(todayStr));

    // const Export = ({ onExport }) => (
    //     <button className="btn btn-secondary" onClick={() => onExport()}>Export</button>
    // )

    // const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(datatableList)} />, [datatableList]);

    // // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
    // function convertArrayOfObjectsToCSV(array) {
    //     let result;

    //     const columnDelimiter = ',';
    //     const lineDelimiter = '\n';
    //     const keys = Object.keys(datatableList[0]);

    //     result = '';
    //     result += keys.join(columnDelimiter);
    //     result += lineDelimiter;

    //     array.forEach(item => {
    //         let ctr = 0;
    //         keys.forEach(key => {
    //             if (ctr > 0) result += columnDelimiter;

    //             result += item[key];
    //             // eslint-disable-next-line no-plusplus
    //             ctr++;
    //         });
    //         result += lineDelimiter;
    //     });

    //     return result;
    // }

    // // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
    // function downloadCSV(array) {
    //     const link = document.createElement('a');
    //     let csv = convertArrayOfObjectsToCSV(array);
    //     if (csv == null) return;

    //     const filename = 'export.csv';

    //     if (!csv.match(/^data:text\/csv/i)) {
    //         csv = `data:text/csv;charset=utf-8,${csv}`;
    //     }

    //     link.setAttribute('href', encodeURI(csv));
    //     link.setAttribute('download', filename);
    //     link.click();
    // }



    const fetchDataList = async () => {
        try {
            setIsFetching(true);
            presentLoading();
            const params = {
                start: dateTimeParameterFormat(startDate),
                end: dateTimeParameterFormat(endDate.setHours(23, 59, 59, 999)),
            };
            const fetch = await api.get(`phone-number/search/logs`, { params: params });
            const fmData = formatDatatableList(fetch.data)
            console.log(fmData)
            setDatatableList(fmData);
            // dataStoreContext.setQueryParams(params);
            // dataStoreContext.setDataStoreState(fetch.data);
        } catch (error) {
        } finally {
            setIsFetching(false);
            dismissLoading();
        }
    }

    const formatDatatableList = (data) => {
        const tmpList = [];
        for (const d of data) {
            let dt = dateTimeDefaultFormat(d.search_date);
            let nm = `(${d.search_value.number_prefix})` + d.search_value.number.replace(/\*/g, "-");
            let pvd = parseInt(d.search_value.provider) !== 0 ? prefix.sim_provider[d.search_value.provider].provider_name : t('all');
            let pkg = parseInt(d.search_value.package) !== 0 ? prefix.sim_package_type[d.search_value.package].name : t('all');
            let sum = parseInt(d.search_value.sum) !== 0 ? d.search_value.sum : t('all');
            let like = d.search_value.like;
            let dislike = d.search_value.dislike;
            const range = d.search_value.price.split("-").map(numStr => parseInt(numStr));
            let price = "";
            if (range[0] > 0 && range[1] > 0) {
                price = currencyFormat(range[0], 0) + " - " + currencyFormat(range[1], 0);
            } else if (range[0] === 0 && range[1] > 0) {
                price = t('less_than') + " " + currencyFormat(range[1], 0);
            } else if (range[0] > 0 && range[1] === 0) {
                price = t('greater_than') + " " + currencyFormat(range[0], 0);
            } else {
                price = t('all');
            }
            let ip = d.user_ip_address !== null ? d.user_ip_address : '';
            let country = d.user_location !== null ? d.user_location.country_name : '';
            let latlon = d.user_location !== null ? `${d.user_location.latitude},${d.user_location.longitude}` : '';
            let o = {
                'datetime': dt,
                'number': nm,
                'provider': pvd,
                'package': pkg,
                'sum': sum,
                'like': like,
                'dislike': dislike,
                'price': price,
                'ip': ip,
                'country': country,
                'latlon': latlon
            }
            tmpList.push(o);
        }
        return (tmpList);
    }

    const csvColumns = [{
        id: 'datetime',
        displayName: t('date_time')
    }, {
        id: 'number',
        displayName: t('phone_number')
    }, {
        id: 'provider',
        displayName: t('sim_provider')
    }, {
        id: 'package',
        displayName: t('sim_package_type')
    }, {
        id: 'sum',
        displayName: t('phone_number_sum')
    }, {
        id: 'like',
        displayName: t('phone_number_like')
    }, {
        id: 'dislike',
        displayName: t('phone_number_dislike')
    }, {
        id: 'price',
        displayName: t('price')
    }, {
        id: 'ip',
        displayName: t('ip_address')
    }, {
        id: 'country',
        displayName: t('country')
    }, {
        id: 'latlon',
        displayName: t('lat_lon')
    }
    ];

    const submitSearch = () => {
        fetchDataList();
    }

    const columns = [
        {
            name: t('date_time'),
            selector: row => {
                let dt = row.datetime.split(" ");
                return <div className="small">
                    <div>{dt[0]}</div>
                    <div>{dt[1]}</div>
                </div>
            },
        },
        {
            name: t('phone_number'),
            selector: row => {
                return <div className="small" style={{ letterSpacing: '1px' }}>{row.number}</div>
            },
            minWidth: '150px'
        },
        {
            name: t('sim_provider'),
            selector: row => {
                return <div className="small">{row.provider}</div>
            },
        },
        {
            name: t('sim_package_type'),
            selector: row => {
                return <div className="small">{row.package}</div>
            },
        },
        {
            name: t('phone_number_sum'),
            selector: row => <div className="small">{row.sum}</div>
        },
        {
            name: t('phone_number_like'),
            selector: row => <div className="small">{row.like}</div>
        },
        {
            name: t('phone_number_dislike'),
            selector: row => <div className="small">{row.dislike}</div>
        },
        {
            name: t('price'),
            selector: row => <div className="small">{row.price}</div>,
            minWidth: '180px'
        },
        {
            name: t('ip_address'),
            selector: row => <div className="small">{row.ip}</div>
        },
        {
            name: t('country'),
            selector: row => <div className="small">{row.country}</div>

        },
        {
            name: t('lat_lon'),
            selector: row => <div className="small">{row.latlon}</div>,
            minWidth: '150px'
        },
    ];

    // const onChangePage = (p) => {
    //     dataStoreContext.setDataCurrentPage(p);
    // }

    // const columns = [
    //     {
    //         name: t('date_time'),
    //         selector: row => {
    //             let dt = dateTimeDefaultFormat(row.search_date).split(" ");
    //             return <div className="small">
    //                 <div>{dt[0]}</div>
    //                 <div>{dt[1]}</div>
    //             </div>
    //         },
    //     },
    //     {
    //         name: t('phone_number'),
    //         selector: row => {
    //             const num = `(${row.search_value.number_prefix})` + row.search_value.number.replace(/\*/g, "-");
    //             return <div className="small" style={{letterSpacing: '1px'}}>{num}</div>
    //         },
    //         minWidth: '120px'
    //     },
    //     {
    //         name: t('sim_provider'),
    //         selector: row => {
    //             return <div className="small">{parseInt(row.search_value.provider) !== 0 ? <>{prefix.sim_provider[row.search_value.provider].provider_name}</> : <>{t('all')}</>}</div>
    //         },
    //     },
    //     {
    //         name: t('sim_package_type'),
    //         selector: row => {
    //             return <div className="small">{parseInt(row.search_value.package) !== 0 ? <>{prefix.sim_package_type[row.search_value.package].name}</> : <>{t('all')}</>}</div>
    //         },
    //     },
    //     {
    //         name: t('phone_number_sum'),
    //         selector: row => <div className="small">{parseInt(row.search_value.sum) !== 0 ? <>{row.search_value.sum}</> : <>{t('all')}</>}</div>
    //     },
    //     {
    //         name: t('phone_number_like'),
    //         selector: row => <div className="small">{row.search_value.like}</div>
    //     },
    //     {
    //         name: t('phone_number_dislike'),
    //         selector: row => <div className="small">{row.search_value.dislike}</div>
    //     },
    //     {
    //         name: t('price'),
    //         selector: row => {
    //             const range = row.search_value.price.split("-").map(numStr => parseInt(numStr));
    //             let txt = "";
    //             if (range[0] > 0 && range[1] > 0) {
    //                 txt = currencyFormat(range[0], 0) + " - " + currencyFormat(range[1], 0);
    //             } else if (range[0] === 0 && range[1] > 0) {
    //                 return t('less_than') + " " + currencyFormat(range[1], 0);
    //             } else if (range[0] > 0 && range[1] === 0) {
    //                 txt = t('greater_than') + " " + currencyFormat(range[0], 0);
    //             } else {
    //                 txt = t('all');
    //             }
    //             return <div className="small">{txt}</div>
    //         },
    //         minWidth: '180px'
    //     },
    //     {
    //         name: t('ip_address'),
    //         selector: row => {
    //             if(row.user_ip_address !== null){
    //                 return <div className="small">{row.user_ip_address}</div>;
    //             }
    //             return "";
    //         }
    //     },
    //     {
    //         name: t('country'),
    //         selector: row => {
    //             if(row.user_location !== null){
    //                 return <div className="small">{row.user_location.country_name}</div>;
    //             }
    //             return "";
    //         }
    //     },
    //     {
    //         name: t('lat_lon'),
    //         selector: row => {
    //             if(row.user_location !== null){
    //                 return <div className="small">{row.user_location.latitude},{row.user_location.longitude}</div>;
    //             }
    //             return "";
    //         },
    //         minWidth: '150px'
    //     },
    // ];


    return (
        <>
            <MainNav pageTitle={t('search_phone_number_logs')}></MainNav>

            <form onSubmit={(e) => e.preventDefault()}>
                <fieldset>
                    <div className="row gx-1 flex-fill flex-wrap">
                        <div className="col-md-12 col-lg-10 col-xl-8">
                            <div className="row gx-1 mb-3">
                                <div className="col-6 col-md-4">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </div>
                                <div className="col-6 col-md-4">
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={startDate}
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </div>
                                <div className="col-6 col-md-4 mt-3 mt-md-0 d-flex">
                                    <PsButton type="submit" icon={<BsSearch />} handleClick={submitSearch}>{t('search')}</PsButton>
                                    <CsvDownload
                                        filename={`search-results-${dateDefaultFormat(startDate)}-${dateDefaultFormat(endDate)}`}
                                        extension=".csv"
                                        separator=';'
                                        columns={csvColumns}
                                        datas={datatableList}>
                                        <button className="btn btn-secondary ms-2"><RiFileExcel2Fill /> Export</button>
                                    </CsvDownload>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </form>


            <div className="small">
                <DataTable
                    columns={columns}
                    // data={filteredItems}
                    data={datatableList}
                    pagination
                    striped
                    // paginationDefaultPage={dataStoreContext.hasDataStore() ? dataStoreContext.dataCurrentPage : 1}
                    // onChangePage={onChangePage}
                    paginationComponentOptions={{
                        noRowsPerPage: true
                    }}
                // actions={actionsMemo}
                // subHeader
                // subHeaderComponent={subHeaderComponentMemo}
                />
            </div>
        </>
    )
}
