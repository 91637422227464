import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import MainNav from '../nav/MainNav';
import api from '../../js/api';
import alert from '../../js/alert';
import FaqForm from './faq/FaqForm';
import { FORM_SUBMIT_TYPE } from '../../js/utilities';
import { useDataStoreContext } from '../../context/DataStoreContext';
import { useNavigate } from 'react-router-dom';

export default function PageFaqAdd() {
    const { t } = useTranslation();
    const formRef = useRef();
    const navigate = useNavigate();

    const dataStorecontext = useDataStoreContext();

    const onSubmit = async (data) => {
        try {
            const submit = await api.post(`faq`, data);
            onSuccess(submit, data);
        } catch (error) {
            alert.toastError(alert.tryGetErrorMessage(error));
        } 
    }

    const onSuccess = (result, submitData) => {
        formRef.current.resetForm()
        dataStorecontext.insertDataRow(result.data.result);
        alert.toastSuccess(alert.tryGetSuccessMessage(result));
        navigate(`/faq`);
    }

    return (
        <>
            <MainNav pageTitle={`${t('add')}${t('faq')}`}></MainNav>
            <FaqForm formSubmitType={FORM_SUBMIT_TYPE.INSERT} onSubmit={onSubmit} ref={formRef} />
        </>
    )
}

