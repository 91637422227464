import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MdWifiCalling3 } from 'react-icons/md';
import { RiAuctionFill } from 'react-icons/ri';
import { FaRegMoneyBillAlt, FaSignal, FaPhoneAlt, FaWallet } from 'react-icons/fa';
import NumberFormat from 'react-number-format';
import HtmlContent from '../../HtmlContent';

const FormAddPhoneNumber = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const { simPackageType, simProvider, simPaidType, saleStatusOption } = props.prefix;
    const [numbers, setNumbers] = useState("");
    const [price, setPrice] = useState(0);
    const [packageType, setPackageType] = useState(Object.entries(simPackageType)[0][1].id);
    const [paidType, setPaidType] = useState(Object.entries(simPaidType)[1][1].id);
    // const [provider, setProvider] = useState(Object.entries(simProvider)[0][1].id);
    const [provider, setProvider] = useState(0);
    const [saleStatus, setSaleStatus] = useState(Object.entries(saleStatusOption)[0][1].id);
    const [isEditMode, setIsEditMode] = useState(false);

    useImperativeHandle(ref, () => ({
        uuid: props.uuid, price, setPrice, numbers, setNumbers, packageType, setPackageType, provider, setProvider, paidType, setPaidType, saleStatus, setSaleStatus, isEditMode, setIsEditMode, setErrorInfo, errorInfo
    }));

    const [errorInfo, setErrorInfo] = useState({});

    return (
        <form autoComplete="off">
            <fieldset>
                <div className="row gx-1">
                    <div className="col-sm-6 col-md-8 col-lg-6 mb-2">
                        <div className="form-control-group-input text-start">
                            <label className="form-label" htmlFor={`input-phone-number-${props.uuid}`}><FaPhoneAlt /> {t('phone_number')}</label>
                            <textarea name="phone-number" id={`input-phone-number-${props.uuid}`} cols="30" rows="1" placeholder={t('phone_number')} className="form-control" value={numbers} onChange={(e) => setNumbers(e.target.value)} disabled={isEditMode}></textarea>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                        <div className="form-control-group-input text-start">
                            <label className="form-label" htmlFor={`price-${props.uuid}`}><FaRegMoneyBillAlt /> {t('price')}</label>
                            <NumberFormat
                                value={price}
                                disabled={false}
                                thousandSeparator={true}
                                decimalScale={2}
                                displayType="input"
                                type="text"
                                allowNegative={false}
                                allowLeadingZeros={false}
                                className="form-control"
                                name="price"
                                id={`price-${props.uuid}`}
                                onValueChange={(values) => {
                                    const { formattedValue, value } = values;
                                    setPrice(value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                        <div className="form-control-group-input text-start">

                            <label className="form-label" htmlFor={`sim-provider-${props.uuid}`}><FaSignal /> {t('sim_provider')}</label>
                            <select name="sim-provider" id={`sim-provider-${props.uuid}`} className="form-select" value={provider} onChange={(e) => setProvider(parseInt(e.target.value))}>
                                <option value={0}>{t('please_select_provider')}</option>
                                {Object.entries(simProvider).map(([key, value]) => {
                                    return (<option value={value.id} key={`sim-provider-opt-${value.id}`}>{value.provider_name}</option>)
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                        <div className="form-control-group-input text-start">
                            <label className="form-label" htmlFor={`sim-package-type-${props.uuid}`}><MdWifiCalling3 /> {t('sim_package_type')}</label>
                            <select name="sim-package-type" id={`sim-package-type-${props.uuid}`} className="form-select" value={packageType} onChange={(e) => setPackageType(parseInt(e.target.value))}>
                                {Object.entries(simPackageType).map(([key, value]) => {
                                    return (<option value={value.id} key={`sim-package-type-opt-${value.id}`}>{value.name}</option>)
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                        <div className="form-control-group-input text-start">
                            <label className="form-label" htmlFor={`sim-paid-type-${props.uuid}`}><FaWallet /> {t('sim_paid_type')}</label>
                            <select name="sim-paid-type" id={`sim-paid-type-${props.uuid}`} className="form-select" value={paidType} onChange={(e) => setPaidType(parseInt(e.target.value))}>
                                {Object.entries(simPaidType).map(([key, value]) => {
                                    return (<option value={value.id} key={`sim-paid-type-opt-${value.id}`}>{value.name}</option>)
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                        <div className="form-control-group-input text-start">
                            <label className="form-label" htmlFor={`sale-status-${props.uuid}`}><RiAuctionFill /> {t('phone_number_sale_status')}</label>
                            <select name="sale-status" id={`sale-status-${props.uuid}`} className="form-select" value={saleStatus} onChange={(e) => setSaleStatus(parseInt(e.target.value))}>
                                {Object.entries(saleStatusOption).map(([key, value]) => {
                                    return (<option value={value.id} key={`sale-status-type-opt-${value.id}`}>{value.name}</option>)
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="text-danger">{errorInfo ? <HtmlContent html={errorInfo.message} /> : ''}</div>
                </div>
            </fieldset>
        </form>
    )
})

export default FormAddPhoneNumber