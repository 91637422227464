import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import MainNav from '../nav/MainNav';
import SalesPersonForm from './sales-person/SalesPersonForm';
import { useDataStoreContext } from '../../context/DataStoreContext';
import alert from '../../js/alert';
import api from '../../js/api';
import { FORM_SUBMIT_TYPE } from '../../js/utilities';
import { useNavigate } from 'react-router-dom';

export default function PageSalesPersonAdd() {
    const { t } = useTranslation();
    const formRef = useRef();
    const dataStorecontext = useDataStoreContext();
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        try {
            const submit = await api.post(`sales-person`, data);
            onSuccess(submit, data);
        } catch (error) {
            alert.toastError(alert.tryGetErrorMessage(error));
        }
    }

    const onSuccess = (result, submitData) => {
        formRef.current.resetForm()
        dataStorecontext.insertDataRow(result.data.result);
        alert.toastSuccess(alert.tryGetSuccessMessage(result));
        navigate(`/sales-person`);
    }

    return (
        <>
            <MainNav pageTitle={`${t('add')}${t('sales_person')}`}></MainNav>
            <SalesPersonForm formSubmitType={FORM_SUBMIT_TYPE.INSERT} onSubmit={onSubmit} ref={formRef} />
        </>
    )
}
