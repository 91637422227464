import React, { useState, useRef } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import alert from '../../js/alert';
import MainNav from '../nav/MainNav';
import img_logo from './../../logo.png';
import ResetPasswordForm from './reset-password/ResetPasswordForm';

export default function PageResetPassword(props) {
    const { code } = useParams();
    const { t } = useTranslation();
    const [errorInfo, setErrorInfo] = useState({});
    const formRef = useRef();


    const onSuccess = (result) => {
        alert.toastSuccess(alert.tryGetSuccessMessage(result));
    }

    const onSubmit = (data) => {
        setErrorInfo({});
    }

    const onError = (error) => {
        setErrorInfo(error);
    }

    const ErrorMessage = () => {
        return (<>{
            errorInfo.message ?
                <div className="row mt-3">
                    <div className="col-12">
                        <div className="alert alert-danger" role="alert">
                            {errorInfo.message}
                        </div>
                    </div>
                </div>
                : null
        }</>)
    }

    return (props.standalone ?
        <div id="app">
            <div className="container d-flex align-items-center flex-column justify-content-center">
                <div className="w-100">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6">
                            <div className="px-4 py-5 rounded shadow-sm bg-light">
                                <img src={img_logo} className="page-form-logo" alt={process.env.REACT_APP_NAME} />
                                <div className="row mx-0">
                                    <h4 className="text-center ">{t('change_password')}</h4>
                                    <p className="text-black-50">{t('reset_password_instruction')}</p>
                                </div>

                                <div>
                                    <ResetPasswordForm onSuccess={onSuccess} onSubmit={onSubmit} onError={onError} ref={formRef} code={code} />
                                    <ErrorMessage />
                                </div>

                                <div className="d-flex justify-content-between mt-3">
                                    <NavLink to={{ pathname: '/login' }}>{t('login')}</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        :
        <>
            <MainNav pageTitle={t('change_password')}></MainNav>
            <div className="row">
                <div className="col-lg-6">
                    <ResetPasswordForm onSuccess={onSuccess} onSubmit={onSubmit} onError={onError} ref={formRef} code={code} />
                    <ErrorMessage />
                </div>
            </div>
        </>
    )
}
