import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import HookFormItems from '../../form/HookFormItems';
import { RiSave3Fill } from 'react-icons/ri';
import PsButton from '../../element/PsButton';
import alert from '../../../js/alert';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { FORM_SUBMIT_TYPE } from '../../../js/utilities';

const SalesPersonForm = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, control, handleSubmit, reset, formState: { errors, isDirty, isValid, isSubmitted }, } = useForm({ reValidateMode: 'onChange', });
    const [passwordFieldType, setPasswordFieldType] = useState("");
    const [errorInfo, setErrorInfo] = useState({});

    useImperativeHandle(ref, () => ({
        handleSubmit: handleSubmit(onSubmit, onError),
        errors: errors,
        isDirty: isDirty,
        isValid: isValid,
        isSubmitting: isSubmitting,
        resetForm: resetForm,
        errorInfo: errorInfo
    }));


    const inputFields = [
        {
            label: t('fullname'),
            requiredOptions: {
                required: t('please_enter_field_value'),
                maxLength: {
                    value: 100,
                    message: t('form_default_text_length_format_warning')
                }
            },
            attr: {
                name: "fullname",
                type: "text",
                inputMode: undefined,
                placeholder: t('fullname'),
                id: "form-sp-fullname-field",
                autoComplete: "off",
            },
            defaultValue: props.formSubmitType === FORM_SUBMIT_TYPE.UPDATE ? props.formControlData.fullname : undefined,
            className: {
                controlGroup: "col-12 col-md-6"
            }
        },
        {
            label: t('store_name'),
            requiredOptions: {
                maxLength: {
                    value: 100,
                    message: t('form_default_text_length_format_warning')
                }
            },
            attr: {
                name: "store_name",
                type: "text",
                inputMode: undefined,
                placeholder: t('store_name'),
                id: "form-sp-store-name-field",
                autoComplete: "off",
            },
            defaultValue: props.formSubmitType === FORM_SUBMIT_TYPE.UPDATE ? props.formControlData.store_name : undefined,
            className: {
                controlGroup: "col-12 col-md-6"
            }
        },
        {
            label: t('store_detail'),
            requiredOptions: {
                maxLength: {
                    value: 1000,
                    message: t('form_default_textarea_length_format_warning')
                }
            },
            attr: {
                name: "store_detail",
                type: "textarea",
                inputMode: undefined,
                placeholder: t('store_detail'),
                id: "form-sp-store-detail-field",
                autoComplete: "off",
            },
            defaultValue: props.formSubmitType === FORM_SUBMIT_TYPE.UPDATE ? props.formControlData.store_detail : undefined,
            className: {
                controlGroup: "col-12 col-md-6"
            }
        },
        {
            label: t('email'),
            requiredOptions: {
                required: t('please_enter_field_value'),
                maxLength: {
                    value: 100,
                    message: t('form_default_text_length_format_warning')
                }
            },
            attr: {
                name: "email",
                type: "email",
                inputMode: undefined,
                placeholder: t('email'),
                id: "form-sp-email-field",
                autoComplete: "off",
            },
            defaultValue: props.formSubmitType === FORM_SUBMIT_TYPE.UPDATE ? props.formControlData.email : undefined,
            className: {
                controlGroup: "col-12 col-md-6"
            }
        },
        {
            label: t('phone_number'),
            requiredOptions: {
                required: t('please_enter_field_value'),
                maxLength: {
                    value: 100,
                    message: t('form_default_text_length_format_warning')
                },
                pattern: {
                    value: /^[-0-9()+,]+$/,
                    message: t('error')
                }
            },
            attr: {
                name: "phone",
                type: "text",
                inputMode: undefined,
                placeholder: t('phone_number'),
                id: "form-sp-phone-field",
                autoComplete: "off",
            },
            defaultValue: props.formSubmitType === FORM_SUBMIT_TYPE.UPDATE ? props.formControlData.phone : undefined,
            className: {
                controlGroup: "col-12 col-md-6"
            },
            help: t('phone_number_format_help')
        },
        {
            label: t('whatsapp'),
            requiredOptions: {
                maxLength: {
                    value: 100,
                    message: t('form_default_text_length_format_warning')
                }
            },
            attr: {
                name: "whatsapp",
                type: "text",
                inputMode: undefined,
                placeholder: t('whatsapp'),
                id: "form-sp-whatsapp-field",
                autoComplete: "off",
            },
            defaultValue: props.formSubmitType === FORM_SUBMIT_TYPE.UPDATE ? props.formControlData.whatsapp : undefined,
            className: {
                controlGroup: "col-12 col-md-6"
            }
        },
        {
            label: t('line_app'),
            requiredOptions: {
                maxLength: {
                    value: 100,
                    message: t('form_default_text_length_format_warning')
                }
            },
            attr: {
                name: "line",
                type: "text",
                inputMode: undefined,
                placeholder: t('line_app'),
                id: "form-sp-line-field",
                autoComplete: "off",
            },
            defaultValue: props.formSubmitType === FORM_SUBMIT_TYPE.UPDATE ? props.formControlData.line : undefined,
            className: {
                controlGroup: "col-12 col-md-6"
            }
        },
        {
            label: t('fb'),
            requiredOptions: {
                maxLength: {
                    value: 100,
                    message: t('form_default_text_length_format_warning')
                }
            },
            attr: {
                name: "fb",
                type: "text",
                inputMode: undefined,
                placeholder: t('fb'),
                id: "form-sp-fb-field",
                autoComplete: "off",
            },
            defaultValue: props.formSubmitType === FORM_SUBMIT_TYPE.UPDATE ? props.formControlData.fb : undefined,
            className: {
                controlGroup: "col-12 col-md-6"
            }
        },
    ];

    if (props.formSubmitType === FORM_SUBMIT_TYPE.INSERT) {
        inputFields.splice(2, 0,
            {
                label: t('password'),
                requiredOptions: {
                    required: t('please_enter_field_value'),
                    minLength: {
                        value: 6,
                        message: t('password_format_length_warning')
                    },
                    maxLength: {
                        value: 60,
                        message: t('password_format_length_warning')
                    }
                },
                attr: {
                    name: "password",
                    type: passwordFieldType,
                    inputMode: undefined,
                    placeholder: t('password'),
                    id: "form-login-password-field",
                    autoComplete: "off",
                },
                controlTypeToggle: {
                    a: "text",
                    b: "password",
                    refState: passwordFieldType,
                    setRefState: setPasswordFieldType,
                    aIcon: <FiEye />,
                    bIcon: <FiEyeOff />
                },
                className: {
                    controlGroup: "col-12 col-md-6"
                }
            });
    }

    const onSubmit = async (data) => {
        try {
            setErrorInfo({});
            setIsSubmitting(true);
            const submit = await props.onSubmit(data);
        } catch (error) {
        } finally {
            setIsSubmitting(false);
        }
    }

    const onError = (err) => {
        const errMessage = alert.tryGetErrorMessage(err);
        if (props.onError) {
            props.onError({ message: errMessage });
        }
        setErrorInfo({ message: errMessage });
        throw new Error(errMessage);
    }

    const resetForm = () => {
        reset();
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit, onError)} id="form-sp" autoComplete="off">
                <fieldset disabled={isSubmitting}>
                    <div className="row my-0">
                        <HookFormItems formFields={inputFields} register={register} control={control} errors={errors} isDirty={isDirty} isValid={isValid} />
                        <div className="col-12">
                            <PsButton type="submit" icon={<RiSave3Fill />} isLoading={isSubmitting}>{t('submit')}</PsButton>&nbsp;&nbsp;
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    )
})

export default SalesPersonForm