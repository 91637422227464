import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import { useDataStoreContext } from '../../context/DataStoreContext';
import { FaEye } from 'react-icons/fa';
import api from '../../js/api';
import { NavLink } from 'react-router-dom';
import MainNav from '../nav/MainNav';
import './../../css/table.css';
import { useLoadingContext } from '../../context/LoadingContext';
import PsButton from '../element/PsButton';
import { BsSearch, BsPlusCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { console_log } from '../../js/dev';

export default function PageSalesPerson() {
    const { t } = useTranslation();
    const { presentLoading, dismissLoading } = useLoadingContext();
    const formRef = useRef();
    const dataStoreContext = useDataStoreContext();
    const [datatableList, setDatatableList] = useState([]);
    const [datatableQuery, setDatatableQuery] = useState({search: ""});
    const navigate = useNavigate();

    useEffect(() => {
        const hasData = dataStoreContext.hasDataStore();
        console_log(hasData)
        if(!hasData){
            fetchDatatableList();
        }else{
            setDatatableList(dataStoreContext.dataList);
            setDatatableQuery(dataStoreContext.queryParams);
        }
    }, []);

    const fetchDatatableList = async () => {
        try {
            presentLoading();
            const fetch = await api.get(`sales-person`, {params: datatableQuery });
            setDatatableList(fetch.data);
            dataStoreContext.setQueryParams(datatableQuery);
            dataStoreContext.setDataStoreState(fetch.data);
        } catch (error) {
            console.log(error)
        } finally {
            dismissLoading();
        }
    }
    
    const handleChange = (event) => {
        const {name, value} = event.target;
        setDatatableQuery({...datatableQuery, [name]: value});
    }

    const onChangePage = (p) => {
        dataStoreContext.setDataCurrentPage(p);
    }

    const columns = [
        {
            name: t('email'),
            selector: row => row.email.substring(0,100),
            maxWidth: '20%',
        },
        {
            name: t('fullname'),
            selector: row => row.fullname.substring(0,100),
            maxWidth: '20%',
        },
        {
            name: t('store_name'),
            selector: row => row.store_name ? row.store_name.substring(0,100) : "",
            maxWidth: '20%',
        },
        {
            name: t('phone_number'),
            selector: row => row.phone,
            maxWidth: '20%',
        },
        {
            name: t('status'),
            selector: row => row.store_name ? row.store_name.substring(0,100) : "",
            maxWidth: '10%',
            cell: row => (row.active_status === 1 ? <div className="badge badge-pill bg-success">{t('active')}</div> : <div className="badge badge-pill bg-danger">{t('suspense')}</div>)
        },
        {
            name: "",
            selector: row => row.id,
            minWidth: '100px',
            cell: row => (
            <PsButton size="sm" color="secondary" handleClick={() => {navigate(`/sales-person/${row.id}`)}}><FaEye />&nbsp;{t('view_details')}</PsButton>
            )
        },
    ];

    return (
        <>
            <MainNav pageTitle={t('sales_person')}>
                <div>
                    <PsButton color="success" icon={<BsPlusCircle/>} handleClick={() => {navigate('/sales-person/add')}}>{t('add')}</PsButton>
                </div>
            </MainNav>
            <form ref={formRef} className="mb-3" onSubmit={(e) => e.preventDefault()}>
                <fieldset>
                    <div className="row gx-2">
                        <div className="col-sm-6 col-md-4">
                            <input type="text" name="search" className="form-control" placeholder={t('search')} onChange={handleChange} value={datatableQuery.search} />
                        </div>
                        <div className="col-3">
                            <PsButton type="submit" icon={<BsSearch />} handleClick={fetchDatatableList}>{t('search')}</PsButton>
                        </div>
                    </div>
                </fieldset>
            </form>
            <DataTable
                // title={t('sales_person')}
                columns={columns}
                data={datatableList}
                pagination
                striped
                paginationDefaultPage={dataStoreContext.hasDataStore() ? dataStoreContext.dataCurrentPage : 1 }
                onChangePage={onChangePage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
            />
        </>
    )
}
