import React from 'react';
import { useTranslation } from 'react-i18next';
import HtmlContent from '../../HtmlContent';

export default function FaqInfo(props) {
    const { t } = useTranslation();
    return (
        <div>
            <table className="table w-100">
                <tbody>
                    <tr>
                        <th style={{ width: "30%" }}>{t('question')}</th>
                        <td>{props.info.question}</td>
                    </tr>
                    <tr>
                        <th>{t('answer')}</th>
                        <td><HtmlContent html={props.info.answer} /></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
