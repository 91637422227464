const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const extractNumberFromString = (str) => {
  return str.replace(/-\D/g, "");
}

const sortDataBy = (data, byKey, keyDataType) => {
  let sortedData;
  if (keyDataType === 'str') {
    sortedData = data.sort(function (a, b) {
      let x = a[byKey].toLowerCase();
      let y = b[byKey].toLowerCase();
      if (x > y) { return 1; }
      if (x < y) { return -1; }
      return 0;
    });
  } else {
    sortedData = data.sort(function (a, b) {
      return a[byKey] - b[byKey];
    })
  }
  return sortedData;
}

const FORM_SUBMIT_TYPE = Object.freeze({
  INSERT: "insert",
  UPDATE: "update",
  DELETE: "delete",
  GET: "get"
})

// Create our number formatter.
// const currencyFormat = (number, decPlaces = "2", decSep = ".", thouSep = ",") => {
//   decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
//   decSep = typeof decSep === "undefined" ? "." : decSep;
//   thouSep = typeof thouSep === "undefined" ? "," : thouSep;
//   var sign = number < 0 ? "-" : "";
//   var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
//   var j = (j = i.length) > 3 ? j % 3 : 0;

//   return sign +
//     (j ? i.substr(0, j) + thouSep : "") +
//     i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
//     (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
// }

const currencyFormat = (amount, decimalCount = 0, decimal = ".", thousands = ",") => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
}

const dateDefaultFormat = (datetime) => {
  let d = new Date(datetime);
  return pad(d.getDate()) + "/" + pad(d.getMonth() + 1) + "/" + pad(d.getFullYear());
}


const dateTimeDefaultFormat = (datetime) => {
  let d = new Date(datetime);
  return pad(d.getDate()) + "/" + pad(d.getMonth() + 1) + "/" + pad(d.getFullYear()) + " " + pad(d.getHours()) + ":" + pad(d.getMinutes()) + ":" + pad(d.getSeconds());
}

const dateTimeParameterFormat = (datetime) => {
  let d = new Date(datetime);
  return pad(d.getFullYear()) + "-" + pad(d.getMonth() + 1) + "-" + pad(d.getDate())  + " " + pad(d.getHours()) + ":" + pad(d.getMinutes()) + ":" + pad(d.getSeconds());
}

const pad = (n, width = 2, z = 0) => {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

const chunkArray = (array, chunkSize) => {
  let r = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    r.push(chunk);
  }
  return r;
}

const validatePriceFormat = (val) => {
  if(val.trim() === "") {
    return false;
  }

  return /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/.test(val);
}

const validatePhoneNumber = (val) => {
  if(val.trim() === "") {
    return false;
  }

  if (!/^[0-9]+$/.test(val)){
    return false;
  }
  
  if(!val.startsWith("020") && !val.startsWith("030")){
    return false;
  }
  
  if(val.startsWith("020") && val.length !== 11) {
    return false;
  }
  
  if(val.startsWith("030") && val.length !== 10) {
    return false;
  }
  return true;
}

const validatePackageType = (val, packageData) => {
  if(val.trim() === "") {
    return false;
  }

  let index = 0;
  Object.entries(packageData).forEach(([k,p]) => {
    if(p.name === val) {
      index = parseInt(p.id);
      return false;
    }
  });
  return index;
}

const validatePaidType = (val, paidData) => {
  if(val.trim() === "") {
    return false;
  }

  let index = 0;
  Object.entries(paidData).forEach(([k,p]) => {
    if(p.name === val) {
      index = parseInt(p.id);
      return false;
    }
  });
  return index;
}

const validateProvider = (val, providerData) => {
  if(val.trim() === "") {
    return false;
  }

  let index = 0;
  Object.entries(providerData).forEach(([k,p]) => {
    if(p.provider_name.toLowerCase() === val.toLowerCase()) {
      index = parseInt(p.id);
      return false;
    }
  });
  return index;
}

const validateSaleStatus = (val, saleStatusData) => {
  if(val.trim() === "") {
    return false;
  }
  
  let index = 0;
  Object.entries(saleStatusData).forEach(([k,p]) => {
    if(p.name.toLowerCase() === val.toLowerCase()) {
      index = parseInt(p.id);
      return false;
    }
  });
  return index;
}

export { getRandomInt, extractNumberFromString, sortDataBy, FORM_SUBMIT_TYPE, currencyFormat, dateTimeDefaultFormat, pad, dateDefaultFormat, chunkArray, validatePriceFormat, validatePhoneNumber, validatePackageType, validatePaidType, validateProvider, validateSaleStatus, dateTimeParameterFormat }