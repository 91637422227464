import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js";
import draftjsToHtml from 'draftjs-to-html';
import draftToMarkdown from 'draftjs-to-markdown';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './../../css/form.css';
import { marked } from 'marked';

const WYSIWYGEditor = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const [editorState, setEditorState] = useState(props.defaultValue ? () => {
        const blocksFromHTML = convertFromHTML(props.defaultValue)
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        )
    
        return EditorState.createWithContent(contentState)} : () =>  EditorState.createEmpty());
    const [editorContent, setEditorContent] = useState({});

    useImperativeHandle(ref, () => ({
        editorContent: editorContent,
        editorState: editorState,
        resetEditor: resetEditor
    }));

    useEffect(() => {
        // if(draftToMarkdown(convertToRaw(editorState.getCurrentContent())).trim() !== ""){
            const data = draftjsToHtml(convertToRaw(editorState.getCurrentContent()));
            setEditorContent(data)
            props.onContentChange(data);
        // }
    }, [editorState]);

    const resetEditor = () => {
        const e = EditorState.push(editorState, ContentState.createFromText(''));
        setEditorState(e);
    }

    const onEditorStateChange = (newState) => {
        setEditorState(newState)
    };

    const toolbarOptions = {
        fontFamily: {
            options: ['Noto Sans Lao, sans-serif', 'Noto Sans Thai, sans-serif', 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
        },
    }

    return (
        <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={onEditorStateChange}
            toolbar={toolbarOptions}
        />
    )
})

export default WYSIWYGEditor;