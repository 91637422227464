import React from 'react';

export default function MainNav(props) {

    return (
        <div className="page-top">
            <div className="d-flex justify-content-between">
                {props.pageTitle ?
                    <><h3 className="m-0 title">{props.pageTitle}</h3></>
                    : null}
                {props.children ?
                    <>{props.children}</>
                    : null}
            </div>
            <hr />
        </div>
    )
}
