import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import alert from '../../../js/alert';
import { FiUnlock, FiEye, FiEyeOff } from 'react-icons/fi';
import api from '../../../js/api';
import HookFormItems from '../../form/HookFormItems';
import PsButton from '../../element/PsButton';

const ResetPasswordForm = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, control, handleSubmit, reset, formState: { errors, isDirty, isValid }, } = useForm({ reValidateMode: 'onChange', });
    const [errorInfo, setErrorInfo] = useState({});
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordFieldType, setPasswordFieldType] = useState("");
    const [confirmPasswordFieldType, setConfirmPasswordFieldType] = useState("");

    useImperativeHandle(ref, () => ({
        handleSubmit: handleSubmit(onSubmit, onError),
        errors: errors,
        isDirty: isDirty,
        isValid: isValid,
        isSubmitting: isSubmitting,
        errorInfo: errorInfo,
    }));

    const inputFields = [
        {
            label: t('new_password'),
            requiredOptions: {
                required: t('please_enter_field_value'),
                minLength: {
                    value: 6,
                    message: t('password_format_length_warning')
                },
                maxLength: {
                    value: 60,
                    message: t('password_format_length_warning')
                }
            },
            attr: {
                name: "password",
                type: passwordFieldType,
                inputMode: undefined,
                placeholder: t('new_password'),
                id: "form-password-field",
                autoComplete: "off",
            },
            controlTypeToggle: {
                a: "password",
                b: "text",
                refState: passwordFieldType,
                setRefState: setPasswordFieldType,
                aIcon: <FiEye />,
                bIcon: <FiEyeOff />
            },
            callback: {
                onChange: (e) => {
                    setPassword(e.target.value);
                }
            }
        },
        {
            label: t('confirm_password'),
            requiredOptions: {
                required: t('please_enter_field_value'),
                minLength: {
                    value: 6,
                    message: t('password_format_length_warning')
                },
                maxLength: {
                    value: 60,
                    message: t('password_format_length_warning')
                },
                pattern: {
                    value: new RegExp(`^${password}$`),
                    message: t('password_not_match')
                }
            },
            attr: {
                name: "confirm_password",
                type: confirmPasswordFieldType,
                inputMode: undefined,
                placeholder: t('confirm_password'),
                id: "form-confirm-password-field",
                autoComplete: "off",
            },
            controlTypeToggle: {
                a: "password",
                b: "text",
                refState: confirmPasswordFieldType,
                setRefState: setConfirmPasswordFieldType,
                aIcon: <FiEye />,
                bIcon: <FiEyeOff />
            },
            callback: {
                onChange: (e) => {
                    setConfirmPassword(e.target.value);
                }
            }
        },
    ];

    const onSubmit = async (data) => {
        try {
            const aData = { ...data };
            aData['type'] = 1;
            aData['code'] = props.code;

            if (props.onSubmit) {
                props.onSubmit(data);
            }
            setErrorInfo({});
            setIsSubmitting(true);
            const submit = await api.post(`reset-password`, aData);
            reset();
            if (props.onSuccess) {
                props.onSuccess(submit);
            }
        } catch (error) {
            onError(error);
        } finally {
            setIsSubmitting(false);
        }
    }

    const onError = async (err) => {
        const errMessage = alert.tryGetErrorMessage(err);
        if (props.onError) {
            props.onError({ message: errMessage });
        }
        setErrorInfo({ message: errMessage });
        throw new Error(errMessage);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)} id="form-reset-password" autoComplete="off" ref={formRef}>
            <fieldset disabled={isSubmitting}>
                <div className="row my-0">
                    <HookFormItems formFields={inputFields} register={register} control={control} errors={errors} isDirty={isDirty} isValid={isValid} />
                    <div className="col-12">
                        <PsButton type="submit" display="block" icon={<FiUnlock />} isLoading={isSubmitting}>{t('change_password')}</PsButton>
                    </div>
                </div>
            </fieldset>
        </form>
    )
})

export default ResetPasswordForm