import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { MdWifiCalling3 } from 'react-icons/md';
import { RiAuctionFill } from 'react-icons/ri';
import { AiFillStar } from 'react-icons/ai';
import { FaRegMoneyBillAlt, FaSignal, FaPhoneAlt, FaWallet } from 'react-icons/fa';
import NumberFormat from 'react-number-format';
import HtmlContent from '../../HtmlContent';

const FormPhoneNumberRecommended = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const { simPackageType, simProvider, simPaidType, saleStatusOption } = props.prefix;
    const [numbers, setNumbers] = useState("");
    const [price, setPrice] = useState(0);
    const [packageType, setPackageType] = useState(Object.entries(simPackageType)[0][1].id);
    const [paidType, setPaidType] = useState(Object.entries(simPaidType)[0][1].id);
    const [provider, setProvider] = useState(Object.entries(simProvider)[0][1].id);
    const [saleStatus, setSaleStatus] = useState(Object.entries(saleStatusOption)[0][1].id);
    const [recommendedEndDate, setRecommendedEndDate] = useState(null);
    const [recommendedAmount, setRecommendedAmount] = useState(0);
    const [isEditMode, setIsEditMode] = useState(false);

    useImperativeHandle(ref, () => ({
        uuid: props.uuid, price, setPrice, numbers, setNumbers, packageType, setPackageType, provider, setProvider, paidType, setPaidType, saleStatus, setSaleStatus, recommendedEndDate, setRecommendedEndDate, recommendedAmount, setRecommendedAmount, isEditMode, setIsEditMode, setErrorInfo, errorInfo
    }));

    const [errorInfo, setErrorInfo] = useState({});

    return (
        <div>
            <form autoComplete="off">
                <fieldset>
                    <div className="row gx-1">
                        <div className="col-sm-10 col-md-8 col-lg-6 mb-2">
                            <div className="form-control-group-input text-start">
                                <label className="form-label hilight" htmlFor={`phone-number-recommended`}><span><AiFillStar /> {t('phone_number_recommended_setting')}</span></label>
                                <select name="phone-number-recommended" id={`phone-number-recommended`} className="form-select" value={recommendedAmount} onChange={(e) => setRecommendedAmount(parseInt(e.target.value))}>
                                    <option value={0}>{t('not_recommended')}</option>
                                    <option value={7}>{t('days_amount', {n: 7})}</option>
                                    <option value={30}>{t('days_amount', {n: 30})}</option>
                                    <option value={90}>{t('days_amount', {n: 90})}</option>
                                </select>
                            </div>
                        </div>
                        <div className="text-danger">{errorInfo ? <HtmlContent html={errorInfo.message} /> : ''}</div>
                    </div>
                </fieldset>
            </form>
            <br />
            <h6>{t('phone_number_details')}</h6>
            <table className="table table-sm table-striped text-start">
                <tbody>
                    <tr>
                        <td><span><FaPhoneAlt /> {t('phone_number')}</span></td>
                        <td><span>{numbers}</span></td>
                    </tr>
                    <tr>
                        <td><span><FaRegMoneyBillAlt /> {t('price')}</span></td>
                        <td><span><NumberFormat
                            value={price}
                            thousandSeparator={true}
                            decimalScale={2}
                            displayType="text"
                            allowNegative={false}
                            allowLeadingZeros={false}
                        /></span></td>
                    </tr>
                    <tr>
                        <td><span><FaSignal /> {t('sim_provider')}</span></td>
                        <td><span>{simProvider[provider].provider_name}</span></td>
                    </tr>
                    <tr>
                        <td><span><MdWifiCalling3 /> {t('sim_package_type')}</span></td>
                        <td><span>{simPackageType[packageType].name}</span></td>
                    </tr>
                    <tr>
                        <td><span><FaWallet /> {t('sim_paid_type')}</span></td>
                        <td><span>{simPaidType[paidType].name}</span></td>
                    </tr>
                    <tr>
                        <td><span><RiAuctionFill /> {t('phone_number_sale_status')}</span></td>
                        <td><span>{saleStatusOption[saleStatus].name}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
})

export default FormPhoneNumberRecommended