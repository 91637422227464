import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// import HookFormItems from './HookFormItems';
// import PsButton from '../element/PsButton';
// import { useAuthContext } from '../../context/AuthContext';
import alert from '../../../js/alert';
import { FiUnlock } from 'react-icons/fi';
import api from '../../../js/api';
import HookFormItems from '../../form/HookFormItems';
import PsButton from '../../element/PsButton';

const ForgetPasswordForm = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, control, handleSubmit, formState: { errors, isDirty, isValid }, } = useForm({ reValidateMode: 'onChange', });
    const [errorInfo, setErrorInfo] = useState({});

    useImperativeHandle(ref, () => ({
        handleSubmit: handleSubmit(onSubmit, onError),
        errors: errors,
        isDirty: isDirty,
        isValid: isValid,
        isSubmitting: isSubmitting,
        errorInfo: errorInfo,
    }));

    const inputFields = [
        {
            label: t('email'),
            requiredOptions: {
                required: t('please_enter_field_value'),
            },
            attr: {
                name: "email",
                type: "email",
                inputMode: "text",
                placeholder: t('email'),
                id: "form-fpwd-username-field",
                autoComplete: "off",
            }
        },
    ];

    const onSubmit = async (data) => {
        try {
            if (props.onSubmit) {
                props.onSubmit(data);
            }
            setErrorInfo({});
            setIsSubmitting(true);
            const submit = await api.post(`forget-password`, data);
            if (props.onSuccess) {
                props.onSuccess(submit);
            }
        } catch (error) {
            onError(error);
        } finally {
            setIsSubmitting(false);
        }
    }

    const onError = async (err) => {
        const errMessage = alert.tryGetErrorMessage(err);
        if (props.onError) {
            props.onError({ message: errMessage });
        }
        setErrorInfo({ message: errMessage });
        throw new Error(errMessage);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)} id="form-forget-password" autoComplete="off" ref={formRef}>
            <fieldset disabled={isSubmitting}>
                <div className="row my-0">
                    <HookFormItems formFields={inputFields} register={register} control={control} errors={errors} isDirty={isDirty} isValid={isValid} />
                    <div className="col-12">
                        <PsButton type="submit" display="block" icon={<FiUnlock />} isLoading={isSubmitting}>{t('change_password')}</PsButton>
                    </div>
                </div>
            </fieldset>
        </form>
    )
})

export default ForgetPasswordForm