import React, { useState, useImperativeHandle, forwardRef, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import alert from '../../../js/alert';
import api from '../../../js/api';
import HookFormItems from '../../form/HookFormItems';
import PsButton from '../../element/PsButton';
import { RiSave3Fill } from 'react-icons/ri';
import { BsImageFill } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const ContactEdit = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const imgInputRef = useRef();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, control, handleSubmit, setValue, formState: { errors, isDirty, isValid }, } = useForm({ reValidateMode: 'onChange', });
    const [errorInfo, setErrorInfo] = useState({});
    const [ctEmail, setCtEmail] = useState("");
    const [ctPhone, setCtPhone] = useState("");

    useImperativeHandle(ref, () => ({
        handleSubmit: handleSubmit(onSubmit, onError),
        errors: errors,
        isDirty: isDirty,
        isValid: isValid,
        isSubmitting: isSubmitting,
        errorInfo: errorInfo,
    }));

    useEffect(() => {
      
            if (props.contactData[`phone`]) {
                setCtPhone(props.contactData[`phone`]);
                setValue('phone', props.contactData[`phone`]);
            }
            if (props.contactData[`email`]) {
                setCtEmail(props.contactData[`email`]);
                setValue('email', props.contactData[`email`]);
            }
    }, []);

    const inputFields = [
        {
            label: t('phone_number'),
            requiredOptions: {
                maxLength: {
                    value: 100,
                    message: t('form_default_textarea_length_format_warning')
                },
                pattern: {
                    value: /^[-0-9()+,]+$/,
                    message: t('error')
                }
            },
            attr: {
                name: "phone",
                type: "text",
                inputMode: undefined,
                placeholder: t('phone_number'),
                id: `form-contact-phone-field`,
                autoComplete: "off",
                value: ctPhone
            },
            defaultValue: ctPhone,
            className: {
                controlGroup: "col-md-6 col-lg-4 col-xl-3"
            },
            callback: {
                onChange: (e) => {
                    setCtPhone(e.target.value);
                }
            },
            help: t('phone_number_format_help')
        },
        {
            label: t('email'),
            requiredOptions: {
                maxLength: {
                    value: 100,
                    message: t('form_default_textarea_length_format_warning')
                }
            },
            attr: {
                name: "email",
                type: "email",
                inputMode: "email",
                placeholder: t('email'),
                id: `form-contact-email-field`,
                autoComplete: "off",
                value: ctEmail
            },
            defaultValue: ctEmail,
            className: {
                controlGroup: "col-md-6 col-lg-4 col-xl-3"
            },
            callback: {
                onChange: (e) => {
                    setCtEmail(e.target.value);
                }
            }
        },
    ];

    const onSubmit = async (data) => {
        try {
            if (props.onSubmit) {
                props.onSubmit(data);
            }
            setErrorInfo({});
            setIsSubmitting(true);
            const submit = await api.patch(`web-contact/general`, data);
            if (props.onSuccess) {
                await props.onSuccess(submit);
            }
            alert.toastSuccess(alert.tryGetSuccessMessage(submit));
        } catch (error) {
            onError(error);
        } finally {
            setIsSubmitting(false);
        }
    }

    const onError = async (err) => {
        const errMessage = alert.tryGetErrorMessage(err);
        if (props.onError) {
            props.onError({ message: errMessage });
        }
        alert.toastError(errMessage);
        setErrorInfo({ message: errMessage });
        throw new Error(errMessage);
    }

    return (
        <div className="mb-4">
            <h6 className="fw-bold">{t('contact_info')}</h6>
            <form onSubmit={handleSubmit(onSubmit, onError)} id={`form-contact`} autoComplete="off" ref={formRef}>
                <fieldset disabled={isSubmitting}>
                    <div className="row my-0">
                        <HookFormItems formFields={inputFields} register={register} control={control} errors={errors} isDirty={isDirty} isValid={isValid} />
                        <div className="col-12">
                            <PsButton type="submit" icon={<RiSave3Fill />} isLoading={isSubmitting}>{t('save')}</PsButton>
                        </div>
                    </div>

                </fieldset>
            </form>
           <hr />
        </div>
    )
})

export default ContactEdit