import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import api from '../../js/api';
import { useDataStoreContext } from '../../context/DataStoreContext';
import MainNav from '../nav/MainNav';
import './../../css/table.css';
import { FaEye, FaTrash } from 'react-icons/fa';
import PsButton from '../element/PsButton';
import { BsSearch, BsPlusCircle } from 'react-icons/bs';
import { useLoadingContext } from '../../context/LoadingContext';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import alert from '../../js/alert';
import { console_log } from '../../js/dev';

export default function PageFaq() {
    const { t } = useTranslation();
    const { presentLoading, dismissLoading } = useLoadingContext();
    const dataStoreContext = useDataStoreContext();
    const formRef = useRef();
    const [datatableList, setDatatableList] = useState([]);
    const [datatableQuery, setDatatableQuery] = useState({ search: "" });
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);

    useEffect(() => {
        const hasData = dataStoreContext.hasDataStore();
        console_log(hasData);
        if (!hasData) {
            fetchDatatableList();
        } else {
            setDatatableList(dataStoreContext.dataList);
            setDatatableQuery(dataStoreContext.queryParams);
        }
    }, []);

    const fetchDatatableList = async () => {
        try {
            presentLoading();
            const fetch = await api.get(`faq`, { params: datatableQuery });
            setDatatableList(fetch.data);
            dataStoreContext.setQueryParams(datatableQuery);
            dataStoreContext.setDataStoreState(fetch.data);
        } catch (error) {
            console.log(error);
        } finally {
            dismissLoading();
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setDatatableQuery({ ...datatableQuery, [name]: value });
    }

    const onChangePage = (p) => {
        dataStoreContext.setDataCurrentPage(p);
    }

    const deleteItem = (id) => {
        MySwal.fire({
            title: <p>{t('delete_data')}</p>,
            html: (<p>{t('delete_data_confirm')}</p>),
            icon: 'question',
            showCloseButton: true,
            confirmButtonText: (<><FaTrash /> {t('confirm')}</>),
            buttonsStyling: false,
            customClass: {
                confirmButton: 'btn btn-danger',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                deleteItemProcess(id);
            }
        });
    }

    const deleteItemProcess = async (id) => {
        try {
            presentLoading();
            const newData = await dataStoreContext.deleteDataRow(id);
            setDatatableList([...newData]);
            const del = await api.delete(`faq/${id}`);
            alert.toastSuccess(alert.tryGetSuccessMessage(del));
        } catch (error) {
            alert.toastError(alert.tryGetErrorMessage(error));
        } finally {
            dismissLoading();
        }
    }

    const columns = [
        {
            name: t('question'),
            selector: row => row.question.substring(0, 100),
        },
        {
            name: t('answer'),
            selector: row => row.answer.substring(0, 100),
        },
        {
            name: "",
            selector: row => row.id,
            minWidth: '200px',
            cell: row => (
                <div>
                    <PsButton size="sm" color="secondary" containerClassName="my-1 mx-1" handleClick={() => { navigate(`/faq/${row.id}`) }}><FaEye />&nbsp;{t('view_details')}</PsButton>
                    <PsButton size="sm" color="danger" containerClassName="my-1 mx-1" handleClick={() => deleteItem(row.id)}><FaTrash />&nbsp;{t('delete')}</PsButton>
                </div>
            )
        },
    ];

    return (
        <>
            <MainNav pageTitle={t('faq')}>
                <div>
                    <PsButton color="success" icon={<BsPlusCircle />} handleClick={() => { navigate('/faq/add') }}>{t('add')}</PsButton>
                </div>
            </MainNav>
            <form ref={formRef} className="mb-3" onSubmit={(e) => e.preventDefault()}>
                <fieldset>
                    <div className="row gx-2">
                        <div className="col-sm-6 col-md-4">
                            <input type="text" name="search" className="form-control" placeholder={t('search')} onChange={handleChange} value={datatableQuery.search} />
                        </div>
                        <div className="col-3">
                            <PsButton type="submit" icon={<BsSearch />} handleClick={fetchDatatableList}>{t('search')}</PsButton>
                        </div>
                    </div>
                </fieldset>
            </form>
            <DataTable
                // title={t('faq')}
                columns={columns}
                data={datatableList}
                pagination
                striped
                paginationDefaultPage={dataStoreContext.hasDataStore() ? dataStoreContext.dataCurrentPage : 1}
                onChangePage={onChangePage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
            />
        </>
    )
}
