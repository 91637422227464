import React, { useState, useImperativeHandle, forwardRef, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import alert from '../../../js/alert';
import api from '../../../js/api';
import HookFormItems from '../../form/HookFormItems';
import PsButton from '../../element/PsButton';
import { RiSave3Fill } from 'react-icons/ri';
import { BsImageFill } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { imageToCanvas } from '../../../js/imageProcess';

const ContactEditSocial = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const imgInputRef = useRef();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, control, handleSubmit, setValue, formState: { errors, isDirty, isValid }, } = useForm({ reValidateMode: 'onChange', });
    const [errorInfo, setErrorInfo] = useState({});
    const [imageDisplaySrc, setImageDisplaySrc] = useState(null);
    const [socialName, setSocialName] = useState("");
    const [socialUrl, setSocialUrl] = useState("");
    const [socialImg, setSocialImg] = useState("");

    useImperativeHandle(ref, () => ({
        handleSubmit: handleSubmit(onSubmit, onError),
        errors: errors,
        isDirty: isDirty,
        isValid: isValid,
        isSubmitting: isSubmitting,
        errorInfo: errorInfo,
    }));

    useEffect(() => {
        if (props.contactData[`${props.social}_img`]) {
            setImageDisplaySrc(props.contactData[`${props.social}_img`]);
        }
        if (props.contactData[`${props.social}`]) {
            setSocialUrl(props.contactData[`${props.social}`]);
            setValue('url', props.contactData[`${props.social}`]);
        }
        if (props.contactData[`${props.social}_name`]) {
            setSocialName(props.contactData[`${props.social}_name`]);
            setValue('name', props.contactData[`${props.social}_name`]);
        }
    }, []);

    const inputFields = [
        {
            label: t('url'),
            requiredOptions: {
                maxLength: {
                    value: 100,
                    message: t('form_default_textarea_length_format_warning')
                }
            },
            attr: {
                name: "url",
                type: "text",
                inputMode: undefined,
                placeholder: t('url'),
                id: `form-${props.social}-url-field`,
                autoComplete: "off",
                value: socialUrl
            },
            defaultValue: socialUrl,
            className: {
                controlGroup: "col-md-6 col-lg-4 col-xl-3"
            },
            callback: {
                onChange: (e) => {
                    setSocialUrl(e.target.value);
                }
            }
        },
        {
            label: t('display_name'),
            requiredOptions: {
                maxLength: {
                    value: 100,
                    message: t('form_default_textarea_length_format_warning')
                }
            },
            attr: {
                name: "name",
                type: "text",
                inputMode: undefined,
                placeholder: t('display_name'),
                id: `form-${props.social}-name-field`,
                autoComplete: "off",
                value: socialName
            },
            defaultValue: socialName,
            className: {
                controlGroup: "col-md-6 col-lg-4 col-xl-3"
            },
            callback: {
                onChange: (e) => {
                    setSocialName(e.target.value);
                }
            }
        },
        {
            requiredOptions: {},
            attr: {
                name: "image",
                type: "hidden",
                inputMode: undefined,
                placeholder: t('image'),
                id: `form-${props.social}-image`,
                autoComplete: "off",
                value: ''
            },
            defaultValue: "nochange",
            className: {
                controlGroup: "col-md-6 col-lg-4 col-xl-3"
            }
        },
        {
            requiredOptions: {},
            attr: {
                name: "social",
                type: "hidden",
                inputMode: undefined,
                placeholder: t('social'),
                id: `form-${props.social}-social`,
                autoComplete: "off",
            },
            defaultValue: props.social,
            className: {
                controlGroup: "col-md-6 col-lg-4 col-xl-3"
            }
        },
    ];

    if (props.social === "whatsapp") {
        inputFields[0].help = t('whatsapp_format_help');
        inputFields[0].requiredOptions.pattern = {
            value: /^[0-9]+$/,
            message: t('error')
        }
    }

    const onSubmit = async (data) => {
        try {
            if (props.onSubmit) {
                props.onSubmit(data);
            }
            setErrorInfo({});
            setIsSubmitting(true);
            const submit = await api.patch(`web-contact/social`, data);
            if (props.onSuccess) {
                await props.onSuccess(submit);
            }
            setValue('image', "nochange");
            alert.toastSuccess(alert.tryGetSuccessMessage(submit));
        } catch (error) {
            onError(error);
        } finally {
            setIsSubmitting(false);
        }
    }

    const onError = async (err) => {
        const errMessage = alert.tryGetErrorMessage(err);
        if (props.onError) {
            props.onError({ message: errMessage });
        }
        alert.toastError(errMessage);
        setErrorInfo({ message: errMessage });
        throw new Error(errMessage);
    }

    const onPickImage = async (e) => {
        if (imgInputRef.current.files.length > 0) {
            var input = imgInputRef.current;
            var reader = new FileReader();
            reader.onload = function () {
                var dataURL = reader.result;
                setNewImageSource(dataURL);
            };
            reader.readAsDataURL(input.files[0]);
        }
    }

    const setNewImageSource = async (url) => {
        const result = await imageToCanvas(url, 400);
        const resize = result.toDataURL("image/jpeg");
        setImageDisplaySrc(resize);
        setValue('image', resize);
    }

    const removeImage = async () => {
        imgInputRef.current.value = "";
        setValue('image', "");
        setImageDisplaySrc(null);
    }

    const style = {
        displayImage: {
            maxWidth: '150px', marginRight: '15px',
        }
    }

    return (
        <div className="mb-4">
            <h6 className="fw-bold">{t(props.socialTranslate)}</h6>
            <form onSubmit={handleSubmit(onSubmit, onError)} id={`form-${props.social}`} autoComplete="off" ref={formRef}>
                <fieldset disabled={isSubmitting}>
                    <div className="row my-0">
                        <HookFormItems formFields={inputFields} register={register} control={control} errors={errors} isDirty={isDirty} isValid={isValid} />
                        <div className="col-12 mb-3">
                            {imageDisplaySrc &&
                                <>
                                    <div className="d-inline-block">
                                        <img src={imageDisplaySrc} alt={`${props.social} image`} style={style.displayImage} />
                                    </div>
                                    <PsButton icon={<FaTrash />} size="sm" color="danger" handleClick={e => { removeImage(); }}>{t('delete')}</PsButton>
                                    &nbsp;
                                </>
                            }
                            <PsButton icon={<BsImageFill />} size="sm" color="secondary" handleClick={e => { imgInputRef.current.click(); }}>{t('image')}</PsButton>
                        </div>
                        <div className="col-12">
                            <PsButton type="submit" icon={<RiSave3Fill />} isLoading={isSubmitting}>{t('save')}</PsButton>
                        </div>
                    </div>

                </fieldset>
            </form>
            <div className="d-none">
                <input type="file" hidden name={`${props.social}_file_image`} id={`${props.social}_file_image`} accept="image/*" onChange={e => onPickImage(e)} ref={imgInputRef} />
            </div>
            <hr />
        </div>
    )
})

export default ContactEditSocial