import React, { useEffect, useState } from 'react';
import MainNav from './../nav/MainNav';
import { useTranslation } from 'react-i18next';
import api from '../../js/api';
import alert from '../../js/alert';
import { useLoadingContext } from '../../context/LoadingContext';
import './../../css/dashboard.css';
import { FaPhoneAlt, FaStore, FaStar } from 'react-icons/fa';
import { RiAuctionFill } from 'react-icons/ri';
import { currencyFormat } from '../../js/utilities';
import PhoneNumberFormat from '../element/PhoneNumberFormat';

export default function PageHome() {
  const { t } = useTranslation();
  const [dashboardData, setDashboardData] = useState(null);
  const { presentLoading, dismissLoading } = useLoadingContext();

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      presentLoading();
      const fetch = await api.get(`dashboard-summary`);
      setDashboardData(fetch.data);
    } catch (error) {
      alert.error(t('error_please_try_again'))
    } finally {
      dismissLoading();
    }
  }

  return (
    <>
      <MainNav pageTitle={process.env.REACT_APP_NAME}></MainNav>
      {dashboardData !== null &&
        <div className="row">

          <div className="col-12 mb-3">
            <h5 className="fw-bold">{t('summary_data')} - {t('phone_number')}</h5>
          </div>

          <div className="col-12 mb-3">
            <div className="row">
              <div className="col-md-6 col-lg-3 mb-3">
                <div className="bg-primary card-counter">
                  <span className="icon"><FaPhoneAlt /></span>
                  <h1 className="fw-bold">{dashboardData.total_phone_number}</h1>
                  <h6 className="label">{t('total_phone_number')}</h6>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-3">
                <div className="bg-warning card-counter">
                  <span className="icon"><FaStore /></span>
                  <h1 className="fw-bold">{dashboardData.total_phone_number_ready}</h1>
                  <h6 className="label">{t('total_phone_number_ready')}</h6>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-3">
                <div className="bg-info card-counter">
                  <span className="icon"><RiAuctionFill /></span>
                  <h1 className="fw-bold">{dashboardData.total_phone_number_sold}</h1>
                  <h6 className="label">{t('total_phone_number_sold')}</h6>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-3">
                <div className="bg-success card-counter">
                  <span className="icon"><FaStar /></span>
                  <h1 className="fw-bold">{dashboardData.total_phone_number_recommended}</h1>
                  <h6 className="label">{t('total_phone_number_recommended')}</h6>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-xl-4 mb-3">
            <h6 className="fw-bold">{t('top_n_phone_number_expensive', { n: dashboardData.phone_number_most_expensive.length })}</h6>
            <table className="table table-sm table-striped">
              <thead className="bg-dark text-white text-center ">
                <tr>
                  <th>{t('phone_number')}</th>
                  <th>{t('price')}</th>
                  <th>{t('store_price')}</th>
                </tr>
              </thead>
              <tbody>
                {dashboardData.phone_number_most_expensive.map((item, index) => {
                  return (
                    <tr key={`table-row-top-expensive-phone-number-${index}`}>
                      <td><PhoneNumberFormat number={item.number} /></td>
                      <td className="text-end">{currencyFormat(item.price)}</td>
                      <td className="text-end">{currencyFormat(item.store_price)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>

          <div className="col-md-6 col-xl-4 mb-3">
            <h6 className="fw-bold">{t('sim_provider')}</h6>
            <table className="table table-sm table-striped">
              <thead className="bg-dark text-white text-center ">
                <tr>
                  <th>{t('sim_provider')}</th>
                  <th>{t('total_phone_number')}</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(dashboardData.provider).map(([key, item]) => {
                  return (
                    <tr key={`table-row-provider-${key}`}>
                      <td>
                        <img src={item.provider_image} alt={item.provider_name} style={{width: '36px'}} className="me-3" />
                        {item.provider_name}
                        </td>
                      <td className="text-end">{t('total_phone_number_amount_n', {n: item.total_phone_number})}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>

          <div className="col-md-6 col-xl-4 mb-3">
            <h6 className="fw-bold">{t('phone_number_config')}</h6>
            <table className="table table-sm table-striped">
              <thead className="bg-dark text-white text-center ">
                <tr>
                  <th>{t('min_price')}</th>
                  <th>{t('phone_number_sell_fee')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-end">{currencyFormat(dashboardData.phone_number_config.pn_min_price)}</td>
                  <td className="text-end">{dashboardData.phone_number_config.pn_sell_fee}%</td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      }
    </>
  )
}
