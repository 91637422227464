import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiLogIn, FiEye, FiEyeOff } from 'react-icons/fi';
import HookFormItems from './HookFormItems';
import PsButton from '../element/PsButton';
import { useAuthContext } from '../../context/AuthContext';
import alert from '../../js/alert';

const FormLogin = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const authContext = useAuthContext();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, control, handleSubmit, formState: { errors, isDirty, isValid }, } = useForm({ reValidateMode: 'onChange', });
    const [passwordFieldType, setPasswordFieldType] = useState("");
    const [errorInfo, setErrorInfo] = useState({});

    useImperativeHandle(ref, () => ({
        handleSubmit: handleSubmit(onSubmit, onError),
        errors: errors,
        isDirty: isDirty,
        isValid: isValid,
        isSubmitting: isSubmitting,
        errorInfo: errorInfo,
    }));

    const loginFields = [
        {
            label: t('username'),
            requiredOptions: {
                required: t('please_enter_field_value'),
            },
            attr: {
                name: "username",
                type: "text",
                inputMode: "text",
                placeholder: t('username'),
                id: "form-login-username-field",
                autoComplete: "off",
            }
        },
        {
            label: t('password'),
            requiredOptions: {
                required: t('please_enter_field_value'),
            },
            attr: {
                name: "password",
                type: passwordFieldType,
                inputMode: undefined,
                placeholder: t('password'),
                id: "form-login-password-field",
                autoComplete: "off",
            },
            controlTypeToggle: {
                a: "password",
                b: "text",
                refState: passwordFieldType,
                setRefState: setPasswordFieldType,
                aIcon: <FiEye />,
                bIcon: <FiEyeOff />
            }
        }
    ];

    const onSubmit = async (data) => {
        try {
            if (props.onSubmit) {
                props.onSubmit(data);
            }
            setErrorInfo({});
            setIsSubmitting(true);
            const login = await authContext.logIn(data);
            if (props.onSuccess) {
                props.onSuccess(login);
            }
        } catch (error) {
            onError(error);
        } finally {
            setIsSubmitting(false);
        }
    }

    const onError = async (err) => {
        const errMessage = alert.tryGetErrorMessage(err);
        if (props.onError) {
            props.onError({ message: errMessage });
        }
        setErrorInfo({ message: errMessage });
        throw new Error(errMessage);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)} id="form-login" autoComplete="off" ref={formRef}>
            <fieldset disabled={isSubmitting}>
                <div className="row my-0">
                    <HookFormItems formFields={loginFields} register={register} control={control} errors={errors} isDirty={isDirty} isValid={isValid} />

                    <div className="col-12">
                        <PsButton type="submit" display="block" icon={<FiLogIn />} isLoading={isSubmitting}>{t('login')}</PsButton>
                    </div>
                </div>
            </fieldset>
        </form>
    )
})

export default FormLogin