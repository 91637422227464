import React, { createContext, useContext, useState, useEffect } from 'react'
import api, { setBaseUrl } from './../js/api'
import i18n, { availableLanguages, fallbackLng } from '../i18nextConf';
import { useLoadingContext } from './LoadingContext';

export const PrefixContext = createContext();

const PrefixContextProvider = (props) => {
    const [prefix, setPrefix] = useState(null);
    const [isCompleted, setIsCompleted] = useState(false);
    const [error, setError] = useState(undefined);
    const [language, setLanguage] = useState(null);
    const { presentLoading, dismissLoading} = useLoadingContext();
    const [pnConfig, setPnConfig] = useState({});

    useEffect(() => {
        const lang = getCurrentLanguage();
        setCurrentLanguage(lang === null ? fallbackLng[0] : lang);
        getPrefix();
        getPnConfig();
    }, []);

    const getPrefix = async () => {
        try {
            presentLoading();
            const p = await api.get(`prefix`);
            await setPrefix(p.data);
        } catch (error) {
            setError(error);
        } finally {
            setIsCompleted(true);
            dismissLoading();
        }
    }

    const getCurrentLanguage = () => {
        if(process.env.NODE_ENV === "production") {
            return "la";
        }
        if(language !== null)
            return language;
        return localStorage.getItem("lang");
    }

    const setCurrentLanguage = (l) => {
        setLanguage(l);
        i18n.changeLanguage(l);
        localStorage.setItem("lang", l);
        setBaseUrl();
        return l;
    }

    const getPnConfig = async () => {
        try {
            const fetchConfig = await api.get(`phone-number/config`);
            setPnConfig(fetchConfig.data);
        } catch (error) {
        } finally {
        }
    }

    const value = { prefix, isCompleted, error, getCurrentLanguage, setCurrentLanguage, getPnConfig, pnConfig };

    return (
        <PrefixContext.Provider value={value}>
            {
                isCompleted ? props.children : null
            }
            {/* {} */}
        </PrefixContext.Provider>
    )
}

function usePrefixContext() {
    const context = useContext(PrefixContext)
    if (context === undefined) {
        throw new Error('usePrefix must be used within a PrefixProvider')
    }
    return context
}

export { usePrefixContext }

export default PrefixContextProvider;