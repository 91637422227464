import React, { useEffect, useRef } from 'react';
import { useLayoutContext } from '../../context/LayoutContext';
import MainSidebar from '../nav/MainSidebar';
import MainHeader from '../nav/MainHeader';
import MainFooter from '../nav/MainFooter';
import { useLocation } from 'react-router-dom';

export default function PageWrapper(props) {
    const layoutcontext = useLayoutContext();
    const pageWrapperRef = useRef();
    const location = useLocation()

    useEffect(() => {
        layoutcontext.toggleOffSidebar();
    }, [location]);

    return (props.enableNav !== false
        ?
        <div id="app" ref={pageWrapperRef}>
            <MainSidebar />
            <div id="main" className="d-flex flex-column">
                <MainHeader />
                <main className="container-fluid py-3">
                    {props.children}
                </main>
                <MainFooter />
            </div>
        </div>
        : <>{props.children}</>
    )
}
