
const calculateImageTransform = (img, max_size = 1000) => {
    let width = img.width;
    let height = img.height;
    let wm_width = img.width;
    let wm_height = img.height;

    if (width > height) {
        if (width > max_size) {
            height *= max_size / width;
            width = max_size;
        }

        wm_width = width;
        wm_height = height;
    } else {
        if (height > max_size) {
            width *= max_size / height;
            height = max_size;
        }
        wm_width = width;
        wm_height = height;
    }

    let wm_pos_x = (width - wm_width) / 2;
    let wm_pos_y = (height - wm_height) / 2;

    return {
        positon: { x: wm_pos_x, y: wm_pos_y, },
        scale: { x: wm_width, y: wm_height, },
    }
}

const imageToCanvas = async (src, scale) => {
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => {
            const transform = calculateImageTransform (img, scale);
            const canvas = document.createElement("canvas");
            canvas.width = transform.scale.x;
            canvas.height = transform.scale.y;
            const ctx = canvas.getContext("2d");
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            resolve(canvas);
        };
        img.onerror = reject;
        img.src = src;
    });
}

export {
    imageToCanvas, calculateImageTransform
}