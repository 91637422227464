import React from 'react';
import { FaBars } from 'react-icons/fa';
import logo from './../../logo.png';
import { useLayoutContext } from '../../context/LayoutContext';
import { useAuthContext } from '../../context/AuthContext';

export default function MainHeader() {
    const authContext = useAuthContext();
    const layoutcontext = useLayoutContext();
  return (
    <header ref={layoutcontext.headerRef}>
        <div className="main-sidebar-toggle">
            <button type="button" onClick={() => {layoutcontext.toggleSidebarHandler();}}><FaBars /></button>
        </div>
        <nav className="navbar navbar-light container-fluid">
            <a className="navbar-brand" href="#"><img src={logo} alt={process.env.REACT_APP_NAME} /> {process.env.REACT_APP_NAME}</a>
        </nav>       
    </header>
  )
}
