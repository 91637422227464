import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MainNav from '../nav/MainNav';
import PhoneNumberFeeForm from './phone-number-config/PhoneNumberFeeForm';
import PhoneNumberMinPriceForm from './phone-number-config/PhoneNumberMinPriceForm';
import api from '../../js/api';
import { usePrefixContext } from '../../context/PrefixContext';

export default function PagePhoneNumberConfig() {
    const { t } = useTranslation();
    const [fee, setFee] = useState(0);
    const [minPrice, setMinPrice] = useState(0);
    const [isFetching, setIsFetching] = useState(false);
    const { prefix, pnConfig, getPnConfig } = usePrefixContext();

    useEffect(() => {
        fetchConfig();
    }, []);

    const fetchConfig = async () => {
        try {
            setIsFetching(true);
            const fetch = await api.get(`phone-number/config`);
            setFee(fetch.data.pn_sell_fee);
            setMinPrice(fetch.data.pn_min_price);
        } catch (error) {

        } finally {
            setIsFetching(false);
        }
    }

    return (
        <>
            <MainNav pageTitle={t('phone_number_config')}></MainNav>
            <div className="row">
                <div className="col-md-6 col-lg-5 col-xl-4">
                    <PhoneNumberFeeForm fee={fee} setFee={setFee} onSuccess={getPnConfig} />
                </div>
                <div className="col-md-6 col-lg-5 col-xl-4">
                    <PhoneNumberMinPriceForm minPrice={minPrice} setMinPrice={setMinPrice} onSuccess={getPnConfig} />
                </div>
            </div>
        </>
    )
}
